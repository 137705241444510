<template>
  <div class="mainCollaborationTrend">
    <div class="upperCollaborationTrend">
      <button
        @click="toggleVisibility"
        :style="{
          backgroundColor: showTeamsAndTags ? '#f49718' : 'white',
          color: showTeamsAndTags ? 'white' : 'black',
        }"
      >
        <img src="@/assets/eye-slash.svg" alt="eye-slash" v-show="showTeamsAndTags" />
        <img src="@/assets/eye.svg" alt="eye" v-show="!showTeamsAndTags" />
        {{ showTeamsAndTags ? "Hide" : "Show" }} Teams/Tags
      </button>
      <TeamsTagsSelection
        ref="teamsTagsSelection"
        v-show="showTeamsAndTags"
        :selectedTeamsValues="selectedTeamsValues"
        :selectedTagsValues="selectedTagsValues"
        @update-teams="handleTeamsUpdate"
        @update-tags="handleTagsUpdate"
        :teamsOptions="teamsOptions"
        :tagsOptions="tagsOptions"
        :max="2"
      />
      <!-- <button
        class="hideAverage"
        v-show="showTeamsAndTags"
        @click="toggleAverageChart"
      >
        <img
          src="@/assets/black-eyeLash.svg"
          style="
             {
              fill: #333;
            }
          "
          v-show="showAverageChart"
        />
        <img
          src="@/assets/eye.svg"
          style="
             {
              fill: #333;
            }
          "
          v-show="!showAverageChart"
        />
        {{ showAverageChart ? "Hide" : "Show" }} Average
      </button> -->
    </div>
    <div class="lowerCollaborationTrend">
      <div class="leftCollaborationContainer">
        <div class="topCollaborationChartContainer">
          <h1>Collaboration Trend Over Time</h1>
          <select v-model="KSSSelectFilter" @change="applyKSSFilter">
            <option
              v-for="item in collaborationTrendPeriod"
              :key="item?.identifier"
              :value="item?.identifier"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="collaborationChartContainer">
          <CollaborationTrendChart ref="collaborationTrend" />
        </div>
      </div>
      <div class="rightCollaborationContainer">
        <div class="avgChartContainer" v-show="showAverageChart">
          <div class="topAvgCollaborationContainer">
            <h1>Avg. KSS Trend</h1>

            <select v-model="avgKSSSelectFilter" @change="applyAvgKSSFilter">
              <option
                v-for="item in collaborationTrendPeriod"
                :key="item?.identifier"
                :value="item?.identifier"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="avgCollaborationChart">
            <AvgCollaborationTrendChart ref="avgCollaborationTrend" />
          </div>
        </div>
        <div class="collaborationTextContainer">
          <div style="display: flex">
            <img src="@/assets/light.svg" alt="light" />
            <h1>
              {{
                collaborationTrendText?.headline?.en
                  ? collaborationTrendText?.headline?.en
                  : "Your company collaborates"
              }}
            </h1>
          </div>
          <p>
            {{
              collaborationTrendText?.tip
                ? collaborationTrendText?.tip?.en
                  ? collaborationTrendText?.tip?.en
                  : collaborationTrendText?.text?.en
                : "Great! You've implemented what you've done so far and your employees            are embracing it. More inspiring text on what can still be improved."
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../MngDashboard.css';
import CollaborationTrendChart from '@/components/MngDashboard/collaboration-trend/CollaborationTrendChart.vue';
import AvgCollaborationTrendChart from '@/components/MngDashboard/collaboration-trend/AvgCollaborationChart.vue';
import TeamsTagsSelection from '@/components/MngDashboard/common/TeamsTagsSelection.vue';

export default {
  name: 'CollaborationTrend',
  data() {
    return {
      selectedTeamsValues: [],
      selectedTagsValues: [],
      KSSSelectFilter: 'Last7Days',
      avgKSSSelectFilter: 'Last7Days',
      showTeamsAndTags: false,
      showAverageChart: true,
    };
  },
  components: {
    CollaborationTrendChart,
    AvgCollaborationTrendChart,
    TeamsTagsSelection,
  },
  computed: {
    collaborationTrendOverTimeData() {
      return this.$store.getters.getKss?.collaborationTrendOverTimeData;
    },
    avgCollaborationTrendData() {
      return this.$store.getters.getKss?.avgCollaborationTrendData;
    },
    collaborationTrendText() {
      return this.$store.getters.getKss?.collaborationTrendText;
    },
    teamsOptions() {
      return this.$store.getters.getKss?.teamsOptions;
    },
    tagsOptions() {
      return this.$store.getters.getKss?.tagsOptions;
    },
    collaborationTrendPeriod() {
      return this.$store.getters.getKss?.collaborationTrendPeriod;
    },
  },
  methods: {
    formatSelectedData(selectedTeams, selectedTags) {
      const selectTeamIdentifiers = selectedTeams.map((option) => option.identifier);
      const selectTagIdentifiers = selectedTags.map((option) => option.identifier);

      return [selectTeamIdentifiers, selectTagIdentifiers];
    },

    // --------- Main Function to call the api on every filters change ---------------- //
    async KSSTrendOverAllFunction(isKSSTrend) {
      const [selectTeamIdentifiers, selectTagIdentifiers] = this.formatSelectedData(
        this.selectedTeamsValues,
        this.selectedTagsValues,
      );

      try {
        await this.$store.dispatch('fetchInitialCollaborationTrendData', {
          selectTeamIdentifiers,
          selectTagIdentifiers,
          kssPeriodType: this.KSSSelectFilter,
          avgKssPeriodType: this.avgKSSSelectFilter,
        });
        if (isKSSTrend === 'isKSSTrend') {
          this.$refs.collaborationTrend.collaborationTrendChart(
            this.collaborationTrendOverTimeData,
          );
        } else if (isKSSTrend === 'isAvgKSSTrend') {
          this.$refs.avgCollaborationTrend.avgCollaborationTrendChart(
            this.avgCollaborationTrendData,
          );
        } else {
          this.$refs.collaborationTrend.collaborationTrendChart(
            this.collaborationTrendOverTimeData,
          );
          this.$refs.avgCollaborationTrend.avgCollaborationTrendChart(
            this.avgCollaborationTrendData,
          );
        }
      } catch (error) {
        console.error('Error dispatching action:', error);
      }
    },

    // -----------show / hide Teams and Tags function -------------- //
    toggleVisibility() {
      this.showTeamsAndTags = !this.showTeamsAndTags;
      if (this.selectedTeamsValues.length > 0 || this.selectedTagsValues.length > 0) {
        this.selectedTeamsValues = [];
        this.selectedTagsValues = [];
        this.$refs.teamsTagsSelection.clearSelection();
        this.KSSTrendOverAllFunction('');
      }
    },

    // toggleAverageChart() {
    //   this.showAverageChart = !this.showAverageChart;
    // },

    // --------------- Filters---------------- //
    applyKSSFilter() {
      this.KSSTrendOverAllFunction('isKSSTrend');
    },
    applyAvgKSSFilter() {
      this.KSSTrendOverAllFunction('isAvgKSSTrend');
    },

    // -------------- Teams Handler------------- //
    handleTeamsUpdate(updatedTeams) {
      this.selectedTeamsValues = updatedTeams;
      this.KSSTrendOverAllFunction('');
    },

    // -------------- Tags Handler------------- //
    handleTagsUpdate(updatedTags) {
      this.selectedTagsValues = updatedTags;
      this.KSSTrendOverAllFunction('');
    },

    // ------------ Collaboration Trend Handler --------------- //
    async fetchInitialCollaborationTrendData() {
      this.KSSTrendOverAllFunction('');
    },
  },
  mounted() {
    this.fetchInitialCollaborationTrendData();
  },
};
</script>
