<template>
  <h2 id="txt_comparison">{{ $t('CompareOimComponent.compareWithPastOrTeam') }}</h2>
  <div class="compare-container">
    <div class="com">
      <div class="compare-to">
        <div :class="isLeftSelectionDisabled" id="disable_left">
          <div class="note le">
            <span id="txt_dataMissing">
              {{ $t('CompareOimComponent.notEnoughDataAvailable') }}
            </span>
          </div>
          <div class="list-items" @click.prevent="toggleShowLeftSelections">
            <div class="item checked" id="yourself_left">
              <span class="item-text" id="txt_yourself">
                {{ getLeftSelected?.text[lang] ?? '-' }}
              </span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </div>
          </div>
        </div>
        <div class="team-compare" v-if="showLeftSelections">
          <ul class="list-items">
            <li :class="isCheckedLeft(leftSelectionItem)"
                v-for="leftSelectionItem in getLeftSelection"
                :key="leftSelectionItem"
                @click.prevent="setLeftSelected(leftSelectionItem)">
              <span class="item-text">
                {{ leftSelectionItem?.text[lang] ?? '-' }}
              </span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="middle">
      <h3 id="txt_compare">
        {{ $t('CompareOimComponent.compareTo') }}
      </h3>
    </div>
    <div class="com">
      <div class="compare-to">
        <div :class="isRightSelectionDisabled">
          <div class="note ri">
            <span id="txt_dataMissing_right">
              {{ $t('CompareOimComponent.notEnoughDataAvailable') }}
            </span>
          </div>
          <div class="list-items" @click.prevent="toggleShowRightSelections">
            <div class="item checked" id="yourself_right">
              <span class="item-text" id="txt_yourself_right">
                {{ getRightSelected?.text[lang] ?? '-' }}
              </span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </div>
          </div>
        </div>
        <div class="team-compare" v-if="showRightSelections">
          <ul class="list-items" id="list-items">
            <li :class="isCheckedRight(rightSelectionItem)"
                v-for="rightSelectionItem in getRightSelection"
                :key="rightSelectionItem"
                @click.prevent="setRightSelected(rightSelectionItem)">
              <span class="item-text">
                {{ rightSelectionItem?.text[lang] ?? '-' }}
              </span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-circle-check"/>
                </i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompareOimComponent',
  data() {
    return {
      showLeftSelections: false,
      showRightSelections: false,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    getLeftSelection() {
      return this.$store.state.dashboard.leftSelection;
    },
    getRightSelection() {
      return this.$store.state.dashboard.rightSelection;
    },
    getLeftSelected() {
      return this.$store.state.dashboard.leftSelected;
    },
    getRightSelected() {
      return this.$store.state.dashboard.rightSelected;
    },
    isLeftSelectionDisabled() {
      if (this.getLeftSelection?.length === 0) {
        return 'yourself-compare disabled';
      }
      return 'yourself-compare';
    },
    isRightSelectionDisabled() {
      if (this.getRightSelection?.length === 0) {
        return 'yourself-compare disabled';
      }
      return 'yourself-compare';
    },
  },
  methods: {
    isCheckedLeft(toCompare) {
      if (toCompare === this.getLeftSelected) {
        return 'item checked';
      }
      return 'item';
    },
    isCheckedRight(toCompare) {
      if (toCompare === this.getRightSelected) {
        return 'item checked';
      }
      return 'item';
    },
    toggleShowLeftSelections() {
      this.showLeftSelections = !this.showLeftSelections;
    },
    toggleShowRightSelections() {
      this.showRightSelections = !this.showRightSelections;
    },
    async setLeftSelected(item) {
      try {
        this.toggleShowLeftSelections();
        this.$store.commit('setIsDashboardLoading', true);
        if (item !== this.getLeftSelected) {
          this.$store.commit('setLeftSelected', item);
          await this.$store.dispatch('getPersonalMindsetPlot');
        }
        this.$store.commit('setIsDashboardLoading', false);
      } catch (error) {
        this.$store.commit('setIsDashboardLoading', false);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    async setRightSelected(item) {
      try {
        this.toggleShowRightSelections();
        this.$store.commit('setIsDashboardLoading', true);
        if (item !== this.getRightSelected) {
          this.$store.commit('setRightSelected', item);
          await this.$store.dispatch('getPersonalMindsetPlot');
          await this.$store.dispatch('getDashboardProfileDescription', this.getRightSelected?.type);
        }
        this.$store.commit('setIsDashboardLoading', false);
      } catch (error) {
        this.$store.commit('setIsDashboardLoading', false);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
/*------------------ COMPARE TO -------------------*/
.box .content .oim h2 {
  font-size: 23px;
  padding: 50px 0 10px;
}

.compare-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 47px;
  justify-content: center;
  align-items: flex-start;
}

.compare-container .com {
  margin-top: 20px;
  display: flex;
  box-sizing: border-box;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  max-width: 300px;
  height: 200px;
  cursor: pointer;
  padding-bottom: 8px;
  flex-grow: 2;
}

.compare-container .middle {
  text-align: center;
  padding: 0 3rem;

}

.middle{
  margin-top: 35px;
}

.compare-container .middle h3 {
  font-size: 20px;
}

.compare-container .compare-to {
  width: 100%;
}

.compare-container .compare-to .list-items {
  display: block;
  max-height: 100px;
  overflow-y: scroll;
}

.compare-container .compare-to .list-items::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.compare-container .compare-to .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
  pointer-events: none;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  overflow: hidden;
  z-index: 10;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 24px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/*".item" auf "checked"-Klasse setzten, wenn angeklickt. Dann erscheint Häckchen.*/
.item.checked .check-icon {
  transform: scale(1);
}

/*------------------ DISABLED -------------------*/
/*".yourself-compare" auf "disabled"-Klasse gesetzt,
 dann ist yourself nicht auswählbar für Vergleich*/
.compare-to .yourself-compare.disabled > .list-items {
  color: var(--color-disabled);
  pointer-events: none;
}

.compare-to .yourself-compare.disabled > .list-items .checkbox {
  border: 1px solid var(--color-disabled);
  background-color: var(--color-disabled);
}

.compare-to .yourself-compare.disabled > .list-items .item:hover {
  transform: none;
  cursor: auto;
  pointer-events: none;
}

/*------------------ POPUP: NOTE -------------------*/
.yourself-compare:hover > .note,
.note:hover {
  opacity: 1;
  visibility: hidden;
}

.note{
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  position: absolute;
  top:-50px;
}

.com .compare-to .yourself-compare .note {
  width: 270px;
  padding: 10px;
  margin-bottom: 7px;
  border-radius: 10px;
  color: white;
  background-color: var(--color-orange);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.com .compare-to .yourself-compare {
  position: relative
}

.com .compare-to .yourself-compare.disabled .note.re {
  right: 5px;
}

.com .compare-to .yourself-compare.disabled .note.re {
  left: 5px;
}

.com .compare-to .yourself-compare.disabled:hover .note {
  opacity: 1;
  visibility: visible;
}

/*kleines Viereck am unteren Ende des Kästchens*/
.com .compare-to .yourself-compare.disabled .note::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: var(--color-orange);
  bottom: -8px;
  left: 25%;
  transform: translateX(-50%) rotate(45deg);
}

</style>
