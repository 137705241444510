<template>
  <breadcrumbs-component :bread-crumbs="questionnaireBreadCrumbs" />
  <div class="content-wrapper">
    <div class="wrapper">
      <mission-complete-component v-if="getIsDone" />
      <assessment-expired-component v-if="getIsExpired" />
      <div v-if="!getIsDone && !getIsExpired" class="quesContainer" id="game">
        <div class="question-container-dummy" id="tpl_questioncontainer">
          <div class="templates invisible"
            name="oim-questions" id="tpl_questions" style="display: block;">
            <div id="progress">
              <div id="progressBar">
                <div id="progressBarFull" :style="getProgressStyle"></div>
              </div>
              <p id="progressText" class="progress-prefix">
                {{ getProgressText }}
              </p>
            </div>
            <component :is="questionType" :key="getQuestionnaireIndex" />
          </div>
        </div>
        <div class="btns">
          <button type="button" name="previous-question" id="btn_previous" class="btn-previous"
            @click.prevent="previousQuestion">
            <i>
              <font-awesome-icon icon="fa-arrow-left" />
            </i>
            {{ $t('QuestionnaireComponent.previous') }}
          </button>
          <button v-if="getShowNextButton" type="button"
            name="next-question" id="btn_next" class="btn-next"
            :disabled="submitDisabled" @click.prevent="nextQuestion">
            {{ $t('QuestionnaireComponent.next') }}
            <i>
              <font-awesome-icon icon="fa-arrow-right" />
            </i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import SliderQuestion from '@/components/Home/QuestionnaireComponent/SliderQuestion.vue';
import KssChampionQuestion from '@/components/Home/QuestionnaireComponent/KssChampionQuestion.vue';
import KssChampionUserListQuestion
  from '@/components/Home/QuestionnaireComponent/KssChampionUserListQuestion.vue';
import IntegrativeComplexityQuestion
  from '@/components/Home/QuestionnaireComponent/IntegrativeComplexityQuestion.vue';
import MissionCompleteComponent
  from '@/components/Home/QuestionnaireComponent/MissionCompleteComponent.vue';
import AssessmentExpiredComponent
  from '@/components/Home/QuestionnaireComponent/AssessmentExpiredComponent.vue';

export default {
  props: ['id'],
  name: 'QuestionnaireComponent',
  components: {
    AssessmentExpiredComponent,
    MissionCompleteComponent,
    IntegrativeComplexityQuestion,
    KssChampionQuestion,
    KssChampionUserListQuestion,
    SliderQuestion,
    BreadcrumbsComponent,
  },
  data() {
    return {
      submitDisabled: false,
      questionnaireBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: this.$route.fullPath,
          isActive: true,
          name: this.$t('BreadCrumbs.evaluation'),
        },
      ],
    };
  },
  computed: {
    questionType() {
      return this.$store.getters.getSelectedQuestionType;
    },
    getProgressText() {
      return this.$store.getters.getSelectedQuestionProgressText;
    },
    getChapterText() {
      return this.$store.getters.getSelectedQuestionChapterText;
    },
    getQuestionnaireIndex() {
      return this.$store.getters.getQuestionnaireIndex;
    },
    getProgressStyle() {
      return `width: ${this.$store.getters.getProgress}%;`;
    },
    getIsDone() {
      return this.$store.state.questionnaireManagement.isDone;
    },
    getIsExpired() {
      return this.$store.state.questionnaireManagement.isExpired;
    },
    getForceInput() {
      return this.$store.state.questionnaireManagement.forceInput;
    },
    getAnswer() {
      return this.$store.state.questionnaireManagement.answer;
    },
    getShowNextButton() {
      return this.$store.getters.getShowNextButton;
    },
  },
  methods: {
    async nextQuestion() {
      try {
        this.submitDisabled = true;
        if (this.questionType !== 'IntegrativeComplexityQuestion') {
          if (this.getForceInput) {
            if (!this.getAnswer) {
              this.$store.commit('setToastNotification', {
                id: Math.random(),
                message: this.$t('ToastComponenet.questionnaire.noInputErr'),
                type: 'error',
              });
              this.submitDisabled = false;
              return;
            }
          }
        }
        await this.$store.dispatch('setNextQuestion');
        this.submitDisabled = false;
      } catch (error) {
        this.submitDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    previousQuestion() {
      this.$store.dispatch('setPreviousQuestion');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('getAssessment', {
        id: this.id,
        locale: this.$i18n.locale,
      });
      if (this.$store.state.questionnaireManagement.assessment.type === 'kss'
        && !['expired', 'canceled', 'scheduled'].includes(this.$store.state.questionnaireManagement.assessment.status)) {
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'introKssTracker',
          assessment: this.$store.state.questionnaireManagement.assessment,
        });
      }
      if (this.$store.state.questionnaireManagement.assessment.status === 'submitted') {
        this.$store.commit('setIsDone', true);
      }

      if (['expired', 'canceled', 'scheduled'].includes(this.$store.state.questionnaireManagement.assessment.status)) {
        this.$store.commit('setIsExpired', true);
      }
    } catch (error) {
      if (error.response.data.status === 418) {
        this.$router.push('/not-found');
      }
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: error.response.data.message,
        type: 'error',
      });
    }
  },
  unmounted() {
    this.$store.commit('removeInvolvedUsers');
    this.$store.commit('setIsDone', false);
    this.$store.commit('setIsExpired', false);
  },
};
</script>

<style scoped>
.content-wrapper {
  width: 1024px;
}

.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 0px;
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .content-wrapper {
    width: 100vw;
  }

  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

.quesContainer {
  margin: 0 auto;
  position: relative;
  width:600px;
}

.invisible {
  display: none;
}

.question-container-dummy {
  width: 600px;
  min-height: 540px;
  height: auto;
  display: block;
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-milk);
}

.question-text {
  text-align: center;
  padding: 2rem 0;
}

.smiley {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.3rem;
  width: 400px;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 3rem 0;
  box-sizing: border-box;
}

.btn-next {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 7rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-next:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-previous {
  float: left;
  padding: 5px;
  font-size: 20px;
  width: 8rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background: var(--color-bisque);
  cursor: pointer;
}

.btn-previous:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[disabled=disabled],
button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

/*------------------ PROGRESSBAR -------------------*/
.progress {
  width: 100%;
  display: block;
  justify-content: space-between;
}

.progress-prefix {
  color: var(--color-orange);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.09px;
  line-height: 16px;
}

#progressBar {
  width: 100%;
  height: 0.5rem;
  border-radius: 50px;
  overflow: hidden;
  background: var(--color-light-grey);
  margin-bottom: 0.3rem;
}

#progressBarFull {
  height: 100%;
  background: var(--color-orange);
  width: 10%;
}

/*------------------ SLIDER -------------------*/
.slide-container {
  width: 100%;
  /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 18px;
  /* Specified height */
  border-radius: 50px;
  background: linear-gradient(90deg, var(--color-deep-champagne) 0%,
      var(--color-yellow-orange) 40%, var(--color-orange) 100%);
  /*Background*/
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit-
(Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 30px;
  /* Set a specific slider handle width */
  height: 30px;
  /* Slider handle height */
  border-radius: 50px;
  /* Slider handle radius */
  background: var(--color-light-grey);
  /* background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 30px;
  /* Set a specific slider handle width */
  height: 30px;
  /* Slider handle height */
  border-radius: 50px;
  /* Slider handle radius */
  border: none;
  -webkit-box-shadow: 1px 1px 7px 1px var(--color-dark);
  box-shadow: 1px 1px 7px 1px var(--color-dark);
  background: var(--color-light-grey);
  /* background */
  cursor: pointer;
  /* Cursor on hover */
}
/* style datalist */
.datalist {
  padding: 0;
  margin: 0;
  height: 20px;
  padding-top: 10px;
  padding-bottom: 70px;
}

.datalist li {
  width: 20%;
  float: left;
  height: 10px;
  position: relative;
  box-sizing: border-box;
  font-size: 0.6rem;
  font-weight: 500;
}

.datalist li:nth-child(2) {
  padding-left: 800px;
}

.datalist li:nth-child(3) {
  padding-left: 6%;
}

.datalist li:nth-child(4) {
  padding-left: 11%;
}

.datalist li:nth-child(5) {
  padding-left: 14%;
}

.datalist li::before {
  font-size: 10px;
  position: absolute;
  top: 5px;
  margin-left: 2px;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {

  .chapter {
    margin: 1rem 0;
  }

  .question-container-dummy {
    max-width: 90vw;
    height: auto;
    padding: 0.3rem 0.5rem;
  }

  .question-text {
    padding: 1rem 0.2rem;
  }

  .question-container-dummy .smiley {
    width: 100%;
    padding: 2rem 0;
    overflow: hidden;
  }

  .datalist li:nth-child(2) {
    padding-left: 1%;
  }

  .datalist li:nth-child(3) {
    padding-left: 3%;
  }

  .datalist li:nth-child(4) {
    padding-left: 9%;
  }

  .datalist li:nth-child(5) {
    padding-left: 7%;
  }

  .values-integrative-complexity {
    padding: 0 0 3rem;
    margin: 2rem 0;
  }

  .btns {
    max-width: 90vw;
  }
}

@media (min-width: 521px) and (max-width: 768px) {

  .chapter {
    margin: 1rem 0rem;
  }

  .question-container-dummy {
    max-width: 85vw;
    height: auto;
    padding: 0.4rem 0.5rem;
  }

  .smiley {
    width: 80%;
    overflow: hidden;
  }

  .datalist li:nth-child(5) {
    padding-left: 12%;
  }

  .btns {
    max-width: 85vw;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {

  .chapter {
    margin: 0 0 1rem;
  }

  .question-container-dummy {
    height: auto;
    padding: 0.4rem 0.5rem;
  }

  .smiley {
    width: 70%;
    overflow: hidden;
  }
}

@media (max-width: 1920px) {

  .chapter {
    margin: 0 0 1rem;
  }

  .progress-prefix {
    font-size: 15px;
  }

  .question-container-dummy {
    width: 100%;
    height: auto;
    padding: 0.4rem 0.5rem;
  }

  .smiley {
    width: 60%;
    overflow: hidden;
  }

  .question-text {
    padding: 1rem 2rem 0;
    font-size: 23px;
  }

  .datalist li {
    font-size: 0.8rem;
  }
  .quesContainer{
    width: 100%;
  }
}

@media screen and (min-width: 1921px) {
  .quesContainer {
    max-width: 100%;
    width: 100%;
  }
  .question-container-dummy {
    width: 100%;
    max-width: 100%;
  }
}
</style>
