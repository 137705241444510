<template>
  <canvas id="avgSatisfactionChart" width="100%" height="100%"></canvas>
  <div class="chart-no-data" v-if="!hasData">
    Not enough data to display
  </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
  name: 'AvgEmployeeSatisfactionChart',
  data() {
    return {
      hasData: false,
    };
  },
  methods: {
    avgEmployeeSatisfactionBarChart(avgEmployeeData) {
      // Get the chart container element
      const ctx = document.getElementById('avgSatisfactionChart');

      // Check if a chart already exists on this canvas
      if (this.avgSatisfactionChart) {
        // Destroy the existing chart
        this.avgSatisfactionChart.destroy();
      }

      if (!avgEmployeeData) {
        // Handle the case when avgEmployeeData is undefined
        return;
      }
      // Extract data from this.employeeSatisfactionData
      const labels = avgEmployeeData?.data?.labels ?? [];
      const datasets = avgEmployeeData?.data?.datasets?.filter(
        (dataset) => dataset !== null && typeof dataset !== 'undefined',
      ) ?? [];
      this.hasData = !!datasets.find((s) => s.data.find((z) => z));
      if (this.hasData === false) return;
      const options = avgEmployeeData?.options ?? [];

      const topPercentages = {
        id: 'topPercentages',
        afterDatasetsDraw(chart) {
          const { ctx, scales } = chart;
          chart?.data?.datasets[0]?.data?.forEach((datapoint, index) => {
            const datasetArray = [];
            chart.data?.datasets?.forEach((dataset) => {
              datasetArray.push(dataset?.data[index]);
            });
            function totalSum() {
              if (datasetArray[1]) {
                return datasetArray[0] - datasetArray[1];
              }
              return datasetArray[0];
            }
            if (totalSum() !== null) {
              ctx.font = 'bold 12px sans-serif';
              ctx.fillStyle = `${totalSum() >= 0 ? 'green' : 'orange'}`;
              ctx.textAlign = 'end';
              ctx.fillText(
                totalSum().toFixed(2),
                scales?.x?.right,
                chart.getDatasetMeta(0)?.data[index]?.y,
              );
            }
          });
        },
      };
      this.avgSatisfactionChart = new Chart(ctx, {
        type: avgEmployeeData?.type,
        data: {
          labels, // Populate with your date labels
          datasets, // Assign your datasets
        },
        options: {
          ...options,
          scales: {
            ...options?.scales,
            x: {
              ...options?.scales?.x,
              ticks: {
                ...options?.scales?.x?.ticks,
                callback(value) {
                  return `${value}%`;
                },
              },
            },
          },
        },
        plugins: [topPercentages],
      });
    },
  },
};
</script>
