<template>
  <div class="mainWeeklyReport">
    <div class="weeklyReports">
      <div class="topReports">
        <h1>Your Weekly Report</h1>
        <div class="switchButton">
          <div class="checkboxContainer">
            <input
              type="checkbox"
              id="mySwitch"
              class="switch-input"
              value="isRelative"
              @input="toggleHandler"
            />
            <label for="mySwitch" class="switch-label"></label>
            <span> Relative Data</span>
          </div>
          <button @click="downloadCSV(weeklyReports?.csv)">
            <img src="@/assets/download-csv.svg" alt="download csv" />

            CSV download
          </button>
        </div>
      </div>

      <div class="bottom-reports">
        <!-- ----------- Performance ------------ -->
        <div class="performance">
          <h3>Performance</h3>
          <div class="performanceValues">
            <TrendValues
              :mainValue="formatMillisecondsToTime(weeklyReports?.report?.performance_avgTimeSpent)"
              :trendValue="weeklyReports?.report?.performance_avgTimeSpentTrend"
              trendValuesClassName="timeSpent"
              avgText="Avg. Time spent"
            />

            <TrendValues
              :mainValue="formatMillisecondsToTime(weeklyReports?.report?.performance_avgOvertime)"
              :trendValue="weeklyReports?.report?.performance_avgOvertimeTrend"
              trendValuesClassName="overTime"
              avgText="Avg. Overtime"
            />
          </div>
        </div>
        <!-- ----------- Activity------------ -->
        <div class="activity">
          <h3>Activity</h3>
          <div class="activityValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.activity_avgLogins"
              :trendValue="weeklyReports?.report?.activity_avgLoginsTrend"
              trendValuesClassName="timeSpent"
              avgText="Avg. Login"
            />
            <TrendValues
              :mainValue="weeklyReports?.report?.activity_activeUsers"
              :trendValue="weeklyReports?.report?.activity_activeUsersTrend"
              trendValuesClassName="overTime"
              avgText="Active Users"
            />
          </div>
        </div>
        <!-- ----------- 360 Mindset------------ -->
        <div class="mindSet">
          <h3>360 OMindset</h3>
          <div class="mindSetValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.['360mindset_sent']"
              :trendValue="weeklyReports?.report?.['360mindset_sentTrend']"
              trendValuesClassName="sended"
              avgText="sended"
            />
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.['360mindset_submitted']
                  : weeklyReports?.report?.relative360mindset_submitted
              "
              :trendValue="weeklyReports?.report?.['360mindset_submittedTrend']"
              trendValuesClassName="submitted"
              avgText="submitted"
              :isRelative="isRelative"
            />
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.['360mindset_sentExpired']
                  : weeklyReports?.report?.relative360mindset_sentExpired
              "
              :trendValue="weeklyReports?.report?.['360mindset_sentExpiredTrend']"
              trendValuesClassName="expired"
              avgText="expired"
              :isRelative="isRelative"
            />
          </div>
        </div>
        <!-- ----------- Collaboration Tracker------------ -->
        <div class="collabration">
          <h3>Collaboration Tracker</h3>
          <div class="collabrationValues">
            <TrendValues
              :mainValue="weeklyReports?.report?.collaboration_created"
              :trendValue="weeklyReports?.report?.collaboration_createdTrend"
              trendValuesClassName="created"
              avgText="created"
            />
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.collaboration_submitted
                  : weeklyReports?.report?.relativeCollaboration_submitted
              "
              :trendValue="weeklyReports?.report?.collaboration_submittedTrend"
              trendValuesClassName="submitted"
              avgText="submitted"
              :isRelative="isRelative"
            />

            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.collaboration_expired
                  : weeklyReports?.report?.relativeCollaboration_expired
              "
              :trendValue="weeklyReports?.report?.collaboration_expiredTrend"
              trendValuesClassName="expired"
              avgText="expired"
              :isRelative="isRelative"
            />
          </div>
        </div>

        <!-- ------------- Mood-o-Mat------------ -->
        <div class="moodMat">
          <h3>Mood-o-Mat</h3>
          <div class="moodMatValues">
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.moodomat_submitted
                  : weeklyReports?.report?.relativeMoodomat_submitted
              "
              :trendValue="weeklyReports?.report?.moodomat_submittedTrend"
              trendValuesClassName="submitted"
              avgText="submitted"
              :isRelative="isRelative"
            />
            <TrendValues
              :mainValue="weeklyReports?.report?.moodomat_avgSatisfaction"
              :trendValue="weeklyReports?.report?.moodomat_avgSatisfactionTrend"
              trendValuesClassName="satisfaction"
              avgText="Avg. Satisfaction"
              :isRelative="isRelative"
              isPercentageValue
            />
          </div>
        </div>

        <!-- ------------- Development Tips------------ -->
        <div class="developmentTips">
          <h3>Development Tips</h3>
          <div class="developmentValues">
            <TrendValues
              :mainValue="
                !isRelative
                  ? weeklyReports?.report?.development_avgChecked
                  : weeklyReports?.report?.relativeMoodomat_submitted
              "
              :trendValue="weeklyReports?.report?.development_avgCheckedTrend"
              trendValuesClassName="avgChecked"
              avgText="Avg. Checked"
            />
            <TrendValues
              :mainValue="weeklyReports?.report?.development_avgFeedback"
              :trendValue="weeklyReports?.report?.development_avgFeedbackTrend"
              trendValuesClassName="feedback"
              avgText="Avg. Feedback"
              :isRelative="isRelative"
              isPercentageValue
            />
          </div>
        </div>
      </div>
    </div>
    <div class="usersTeams">
      <div class="users">
        <div class="headingIcon">
          <h6>Total Users</h6>
          <img src="@/assets/user-group.svg" alt="user-group" />
        </div>
        <h1>{{ weeklyReports?.report?.totalUsers }}</h1>
        <h2>vs last quarter {{ weeklyReports?.report?.totalUsersLastQuarter }}</h2>
      </div>
      <div class="teams">
        <div class="headingIcon">
          <h6>Total Teams</h6>
          <img src="@/assets/team.svg" alt="team" />
        </div>
        <h1>{{ weeklyReports?.report?.totalTeams }}</h1>
        <h2>vs last quarter {{ weeklyReports?.report?.totalTeamsLastQuarter }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import TrendValues from '@/components/MngDashboard/weekly-report/TrendValues.vue';
import './weekly-report.css';

export default {
  data() {
    return {};
  },
  components: {
    TrendValues,
  },

  computed: {
    weeklyReports() {
      return this.$store.getters.getWeeklyReport.data;
    },

    isRelative() {
      return this.$store.getters.getWeeklyReport.isRelative;
    },
  },

  methods: {
    // --------------- Toggle Handler -------------------- //
    toggleHandler() {
      this.$store.commit('setIsRelative', !this.isRelative);

      this.fetchInitialWeeklyReport();
    },

    // ------------ Weekly Report Handler --------------- //
    fetchInitialWeeklyReport() {
      this.$store.dispatch('fetchInitialWeeklyReport').catch((error) => {
        // Handle API call errors here
        console.error('Error fetching content:', error);
      });
    },

    // ------------- CSV download Handler ------------------ //
    downloadCSV(csv) {
      // Create a Blob object containing the CSV data
      const blob = new Blob([csv], { type: 'text/csv' });

      // Create a temporary link element for the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'report.csv'; // Specify the desired file name

      // Trigger a click event on the link to initiate the download
      a.click();

      // Cleanup: Remove the temporary link and revoke the Blob URL
      URL.revokeObjectURL(a.href);
    },

    // ------------- Format MilliSecond Handler ------------------ //
    formatMillisecondsToTime(ms = 0) {
      const totalMinutes = ms / (60 * 1000);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.round(totalMinutes % 60);

      if (hours === 0) {
        return `${totalMinutes.toFixed(1)}min`;
      }
      return `${hours}hr ${minutes}min`;
    },
  },

  mounted() {
    this.fetchInitialWeeklyReport();
  },
};
</script>
