import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    data: {},
    isRelative: false,
  },
  getters: {},
  mutations: {
    setWeeklyReports(state, weeklyReports) {
      state.data = weeklyReports;
    },
    setIsRelative(state, value) {
      state.isRelative = value;
    },
  },
  actions: {
    async fetchInitialWeeklyReport({ commit, state }) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_report',
            csv_selected: true,
            isRelative: state.isRelative,
          },
        ],
      };
      const { data } = await NetworkContent.getInteractiveContent(requestData);
      commit('setWeeklyReports', data?.content?.contentSections[0]);
    },
  },
};
