import NetworkContent from '@/helpers/networking/NetworkContent';

async function fetchData(requestData) {
  const { data: response } = await NetworkContent.getInteractiveContent(requestData);
  const oMindSetCompanyData = response?.content?.contentSections[0]?.plot_mindset;
  const mindsetTipText = response?.content?.contentSections[0]?.text_mindsetTip;
  const teamsOptions = response?.content?.contentSections[0]?.list_mindsetTeams?.elements?.map(
    (element) => ({
      name: element?.caption?.de,
      identifier: element?.identifier,
      selected: element?.selected,
    }),
  );
  const tagsOptions = response?.content?.contentSections[0]?.list_mindsetTags?.elements;
  const mindsetPeriod = response?.content?.contentSections[0]?.list_mindsetPeriods?.elements?.map(
    (element) => ({
      name: element?.caption?.de,
      identifier: element?.identifier,
    }),
  );

  return {
    oMindSetCompanyData,
    mindsetTipText,
    teamsOptions,
    tagsOptions,
    mindsetPeriod,
  };
}

export default {
  state: {
    oMindSetCompanyData: [],
    mindsetTipText: {},
    teamsOptions: [],
    tagsOptions: [],
    mindsetPeriod: [],
  },
  getters: {},
  mutations: {
    SET_OMINDSET_COMPANY_DATA(state, data) {
      state.oMindSetCompanyData = data.oMindSetCompanyData;
      state.mindsetTipText = data.mindsetTipText;
      state.teamsOptions = data.teamsOptions;
      state.tagsOptions = data.tagsOptions;
      state.mindsetPeriod = data.mindsetPeriod;
    },
  },
  actions: {
    async fetchInitialMindSetCompanyData({ commit }, payload) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_mindset',
            list_mindset_teams: payload?.selectTeamIdentifiers,
            list_mindset_tags: payload?.selectTagIdentifiers,
            mindsetPeriodType: payload?.mindsetPeriodType,
          },
        ],
      };
      const {
        oMindSetCompanyData,
        mindsetTipText,
        teamsOptions,
        tagsOptions,
        mindsetPeriod,
      } = await fetchData(requestData);
      commit('SET_OMINDSET_COMPANY_DATA', {
        oMindSetCompanyData,
        mindsetTipText,
        teamsOptions,
        tagsOptions,
        mindsetPeriod,
      });
    },
  },
};
