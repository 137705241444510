<template>
  <div class="mainSelectClass">
    <div class="teamsSelectClass">
      <VueMultiselect
        v-model="selectedTeams"
        :options="teamsOptions"
        :multiple="true"
        :close-on-select="false"
        label="name"
        track-by="identifier"
        :preserve-search="true"
        taggable
        tag-placeholder="Add a team"
        tag-label="name"
        :showLabels="false"
        placeholder="Teams"
        class="customTeam-vue-multiselect"
        @select="handleTeamsSelect"
        @remove="handleTeamsUnselect"
        :max="max ? max : null"
        :limit="1"
        :show-pointer="true"
      >
        <template v-slot:option="{ option }">
          <div class="custom-option">
            <font-awesome-icon :icon="teamIcon(option)" class="icon" />
            {{ option.name }}
          </div>
        </template>
        <template v-slot:selection="{ isOpen }">
          <span class="multiselect__single" v-if="selectedTeams.length && !isOpen">
            {{ selectedTeams.length }} teams selected
          </span>
        </template>
      </VueMultiselect>
      <span class="totalSelect" v-show="selectedTeams.length">{{ selectedTeams.length }}</span>
    </div>
    <div class="tagsSelectClass">
      <VueMultiselect
        v-model="selectedTags"
        :options="tagsOptions"
        group-values="childrens"
        group-label="key"
        :multiple="true"
        :close-on-select="false"
        track-by="identifier"
        label="caption"
        :custom-label="tagWithLang"
        :preserve-search="true"
        taggable
        tag-placeholder="Add a tag"
        :showLabels="false"
        placeholder="Tags"
        class="customTeam-vue-multiselect"
        @select="handleTagsSelect"
        @remove="handleTagsUnselect"
        :limit="1"
        :show-pointer="true"
      >
        <template v-slot:option="{ option }">
          <div class="custom-option">
            <template v-if="option.$groupLabel">&nbsp;{{ option.$groupLabel[getLocale] }}</template>
            <template v-if="option.caption">
              <font-awesome-icon :icon="tagsIcon(option)" class="icon" />
              &nbsp;{{ option.caption[getLocale] }}</template>
          </div>
        </template>
        <template v-slot:selection="{ isOpen }">
          <span class="multiselect__single" v-if="selectedTags.length && !isOpen">
            {{ selectedTags.length }} tag selected
          </span>
        </template>
      </VueMultiselect>
      <div class="totalSelect" v-show="selectedTags.length">
        {{ selectedTags.length }}
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  props: {
    selectedTeamsValues: Array,
    selectedTagsValues: Array,
    showTeamsAndTags: Boolean,
    max: Number,
    teamsOptions: Array,
    tagsOptions: Array,
  },
  components: {
    VueMultiselect,
  },
  data() {
    return {
      selectedTeams: this.selectedTeamsValues,
      selectedTags: this.selectedTagsValues,
    };
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    teamIcon(option) {
      return this.selectedTeams
        ?.map((selectedOption) => selectedOption.identifier)
        ?.includes(option?.identifier)
        ? ['fas', 'square-check']
        : ['far', 'square'];
    },
    tagsIcon(option) {
      return this.selectedTags
        ?.map((selectedOption) => selectedOption.identifier)
        ?.includes(option?.identifier)
        ? ['fas', 'square-check']
        : ['far', 'square'];
    },

    handleTeamsSelect(selectedOption) {
      if (!this.selectedTeams.includes(selectedOption)) {
        this.selectedTeams.push(selectedOption);
      }
      this.$emit('update-teams', this.selectedTeams);
    },
    handleTeamsUnselect(removedOption) {
      this.selectedTeams = this.selectedTeams.filter((option) => option !== removedOption);
      this.$emit('update-teams', this.selectedTeams);
    },

    handleTagsSelect() {
      this.$emit('update-tags', this.selectedTags);
    },
    handleTagsUnselect() {
      this.$emit('update-tags', this.selectedTags);
    },
    tagWithLang(option) {
      return option.caption[this.getLocale];
    },
    clearSelection() {
      this.selectedTeams = [];
      this.selectedTags = [];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
