<template>
  <breadcrumbs-component :breadCrumbs="breadCrumbs" />
  <div class="mng-dashboard">
    <WeeklyReport />
    <SatisfactionReport />
    <CollaborationTrend />
    <OMindSet />
  </div>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import WeeklyReport from './weekly-report/WeeklyReport.vue';
import SatisfactionReport from './satisfaction-report/SatisfactionReport.vue';
import CollaborationTrend from './collaboration-trend/CollaborationTrend.vue';
import OMindSet from './O-mind-set/OMindSet.vue';

export default {
  name: 'TeamAdministrationComponent',
  components: {
    BreadcrumbsComponent,
    WeeklyReport,
    SatisfactionReport,
    CollaborationTrend,
    OMindSet,
  },
  data() {
    return {
      breadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/mng-dashboard',
          isActive: true,
          name: this.$t('BreadCrumbs.mngDashboard'),
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 5px !important;
  }
}
</style>
