<template>
  <breadcrumbs-component :breadCrumbs="kssBreadCrumbs"/>
  <div class="kss-container">
    <div class="wrapper">
      <div class="box">
        <div class="tabs-container">
          <input type="radio" name="box" id="alreadyCreated_box"
                  @click="selectedTab = 'alreadyCreated'">
          <label for="alreadyCreated_box" class="navlabel"
                 :class="{ 'selected-tab': selectedTab === 'alreadyCreated' }">
                 {{ $t('KssTrackerComponent.alreadyCreated') }}</label>
          <input type="radio" name="box" id="archive_box"
                 @click="selectedTab = 'archive'">
          <label for="archive_box" class="navlabel"
                 :class="{ 'selected-tab': selectedTab === 'archive' }">
                 {{ $t('KssTrackerComponent.archive') }}</label>
        </div>
        <!----------------- ALREADY CREATED ----------------->
        <div v-if="selectedTab === 'alreadyCreated'" class="content" id="task_card_container">
          <kss-task-created-component v-for="task in createdTasks" :createdTask="task"
                                      :key="task._id" :allKssParticipants="allKssParticipants"
                                      :involvedKssUsers="involvedKssUsers"/>
        </div>
        <!----------------- ARCHIVE ----------------->
        <div v-if="selectedTab === 'archive'" class="content" id="task_card_container_archiv">
          <kss-task-archived-component v-for="task in archivedTasks" :archivedTask="task"
                                       :key="task._id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KssTaskCreatedComponent from '@/components/Home/KssCreator/KssTaskCreatedComponent.vue';
import KssTaskArchivedComponent from '@/components/Home/KssCreator/KssTaskArchivedComponent.vue';
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';

export default {
  name: 'KssTrackerComponent',
  props: {
    tab: {
      type: String,
      default: 'alreadyCreated',
    },
  },
  components: {
    BreadcrumbsComponent,
    KssTaskArchivedComponent,
    KssTaskCreatedComponent,
  },
  data() {
    return {
      selectedTab: this.tab,
      kssBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/kss-tracker',
          isActive: true,
          name: this.$t('BreadCrumbs.kssTracker'),
        },
      ],
    };
  },
  computed: {
    createdTasks() {
      return this.$store.getters.getCurrentCreatedTasks;
    },
    allKssParticipants() {
      return this.$store.getters.getKssParticipants;
    },
    involvedKssUsers() {
      return this.$store.getters.getInvolvedKssUsers;
    },
    archivedTasks() {
      return this.$store.getters.getCurrentArchivedTasks;
    },
  },
  mounted() {
    this.$store.dispatch('getKssTeams');
    // pass the logged in user to select him by default
    this.$store.dispatch('getKssParticipants', this.$store.state.auth.user.name);
    this.$store.dispatch('getArchivedTasks');
    this.$store.dispatch('getCreatedTasks');
  },
  unmounted() {
    this.$store.commit('removeKssTeams');
    this.$store.commit('removeKssParticipants');
    this.$store.commit('removeArchivedTasks');
    this.$store.commit('removeCreatedTasks');
    this.$store.commit('resetInviteEmail');
  },
};
</script>

<style scoped>
.kss-container {
  margin: 0 auto;
  position: relative;
}

h1 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
  padding: var(--card-padding) 0 var(--card-padding);
}

h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h3 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h4 {
  padding: 0.5rem 0;
}

p {
  padding: 0.5rem 0;
}

.link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.photo { /*gilt für alle Fotos*/
  display: block;
  width: 100%;
}

 .navlabel{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin: 4px 8px;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    background-color: var(--color-milk);
    border-radius: 10px;
    flex: 1;
}

.navlabel.selected-tab {
  color: var(--color-orange);
  border: 1px solid var(--color-orange);
}

@media (max-width: 520px) {
  .box .navlabel {
    margin: 16px 6px;
    padding: 16px 16px;
  }}
  @media (min-width: 521px) and (max-width: 768px) {
  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }}

@media (min-width: 769px) and (max-width: 1024px) {
  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }}
.tabs-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

/*---------------- TABSYSTEM NEW > ALREADY > ARCHIVE ------------------*/
.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  max-width: 960px;
  margin: 0 auto;
  width: 100vw;
}

.box {
  display: flex;
  flex-wrap: wrap;
}

.box .content {
  padding: 10px 0px 20px;
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.box input[type="radio"] {
  display: none;
}

.box input[type="radio"]:checked + .navlabel {
  color: var(--color-orange);
}

.box input[type="radio"]:checked + label + .content {
  display: block;
}

/*---------------- CONTENT NEW ------------------*/
.box .content .forms {
  /* max-width: 90vw; */
  /* width: 600px; */
  /*height: auto;*/
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  background: var(--color-milk);
}

.box .content .forms .time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.box .content .forms .time-wrapper .time-box {
  display: flex;
  flex-wrap: wrap;
}

.time-box .label {
  text-align: center;
  display: block;
  width: 350px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: var(--color-orange);
  background-color: var(--color-white);
  cursor: pointer;
}

.time-box .area {
  display: none;
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.time-box input[type="checkbox"] {
  display: none;
}

.time-box input[type="checkbox"]:checked + label + .area {
  display: block;
}

/*---------------- CONTENT NEW: RUNTIME ------------------*/
.runtime {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

/*------------------ DROPDOWN -------------------*/
.box .content .forms .dropdown {
  width: 150px;
  height: 30px;
  display: none;
  /* position: relative; */
  /* display: flex; */
  /* float: right; */
  /* flex-direction: column; */
  margin-top: 30px;
}

select {
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-white);
  box-shadow: 5px 10px 8px var(--color-light-grey);
  cursor: pointer;
}

select option {
  width: 150px;
  height: 30px;
  font-size: 14px;
  padding: 10px;
  margin: 10px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-milk);
  cursor: pointer;
}

label {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: block;
  position: relative;
  margin: 3rem 0;
  box-sizing: border-box;
}

.btn-create {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-delete {
  float: left;
  padding: 5px;
  font-size: 20px;
  width: 8rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
}

.btn-delete i {
  padding-right: 10px;
}

.btn-delete:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-tracker {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.btn-tracker:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .chapter {
    margin: 1rem 0;
  }

  .title {
    margin: 1rem 0;
  }

  .time-box .label {
    margin-left: auto;
    margin-right: auto;
    width: 130px;
  }

  .time-box .label span {
    display: none;
  }

}

@media (min-width: 521px) and (max-width: 768px) {
  .chapter {
    margin: 1rem -2rem;
  }

  .title {
    margin: 1rem -2rem;
  }

  .time-box .label {
    margin-left: auto;
    margin-right: auto;
    width: 230px;
  }

  .time-box .label span {
    display: block;
  }

}

@media (min-width: 769px) and (max-width: 1024px) {
  .chapter {
    margin: 0 -3rem 1rem;
  }

  .title {
    margin: 0 -3rem 1rem;
  }
}

@media (min-width: 1920px) {
  .chapter {
    margin: 0rem -10rem 1rem;
  }

  .title {
    margin: 0rem -10rem 1rem;
  }

  .time-box .label {
    margin-left: auto;
    margin-right: auto;
  }
}

</style>
