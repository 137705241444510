<template>
  <div v-bind:class="trendValuesClassName">
    <h1>
      {{ mainValue }}
      {{ isRelative || isPercentageValue ? "%" : "" }}
    </h1>
    <p>
      {{ avgText }}
      <span
        :style="{
          color: trendValue == null || trendValue <= 0 ? '#f49718' : '',
        }"
      >
        <img
          v-if="trendValue == null || trendValue <= 0"
          src="@/assets/arrow-down.svg"
          alt="arrow-down"
        />
        <img v-else src="@/assets/arrow-up.svg" alt="arrow-up" />
        {{ trendValue }}%</span
      >
    </p>
  </div>
</template>
<script>
import './weekly-report.css';

export default {
  name: 'TrendValues',
  props: {
    mainValue: [Number, String],
    trendValue: Number,
    trendValuesClassName: String,
    avgText: String,
    isRelative: Boolean,
    isPercentageValue: Boolean,
  },
};
</script>
