// eslint-disable-next-line import/prefer-default-export
export function tooltipTeamPlotHelper(context) {
  const { dataset } = context;
  const dataPoint = dataset.data[context.dataIndex];
  if (Array.isArray(dataPoint)) {
    const lastValue = dataPoint[dataPoint.length - 1];
    return [context.dataset.label, parseFloat(Number(lastValue).toFixed(2))];
  }
  return [context.dataset.label, parseFloat(Number(context.parsed.y).toFixed(2))];
}

export function moodomatToolTipHelper(context, dailyCount) {
  if (Array.isArray(dailyCount)) {
    const count = dailyCount.filter((val) => {
      const d1 = new Date(val.x);
      return (context.parsed.x === d1.getTime());
    });
    const newLabel = `${context.dataset.label} : ${parseFloat(Number(context.parsed.y).toFixed(3))} Moodomat count : ${count[0].y}`;
    return [newLabel];
  }
  return [context.dataset.label, context.parsed.y];
}

export function removeTimeToolTipHelper(context) {
  if (Array.isArray(context)) {
    const dateParts = context[0].label.split(',');
    return [dateParts[0].concat(',').concat(dateParts[1])];
  }
  return [context[0].label];
}
