import NetworkContent from '@/helpers/networking/NetworkContent';

async function fetchData(requestData) {
  const { data: response } = await NetworkContent.getInteractiveContent(requestData);
  const employeeSatisfactionData = response?.content?.contentSections[0]?.plot_employeeSatisfaction;
  const avgEmployeeSatisfactionData = response?.content?.contentSections[0]?.plot_avgEmployeeSatisfaction;
  const employeeSatisfactionText = response?.content?.contentSections[0]?.text_employeeSatisfactionTip;
  const teamsOptions = response?.content?.contentSections[0]?.list_employeeSatisfactionTeams?.elements?.map(
    (element) => ({
      name: element?.caption?.de,
      identifier: element?.identifier,
      selected: element?.selected,
    }),
  );
  const tagsOptions = response?.content?.contentSections[0]?.list_employeeSatisfactionTags?.elements;
  const employeeSatisfactionPeriod = response?.content?.contentSections[0]?.list_employeeSatisfactionPeriods?.elements?.map(
    (element) => ({
      name: element?.caption?.de,
      identifier: element?.identifier,
    }),
  );

  return {
    employeeSatisfactionData,
    avgEmployeeSatisfactionData,
    employeeSatisfactionText,
    teamsOptions,
    tagsOptions,
    employeeSatisfactionPeriod,
  };
}

export default {
  state: {
    employeeSatisfactionData: [],
    avgEmployeeSatisfactionData: [],
    employeeSatisfactionText: {},
    teamsOptions: [],
    tagsOptions: [],
    employeeSatisfactionPeriod: [],
  },
  getters: {},
  mutations: {
    SET_EMPLOYEE_SATISFACTION_DATA(state, data) {
      state.employeeSatisfactionData = data.employeeSatisfactionData;
      state.avgEmployeeSatisfactionData = data.avgEmployeeSatisfactionData;
      state.employeeSatisfactionText = data.employeeSatisfactionText;
      state.teamsOptions = data.teamsOptions;
      state.tagsOptions = data.tagsOptions;
      state.employeeSatisfactionPeriod = data.employeeSatisfactionPeriod;
    },
  },
  actions: {
    async fetchInitialEmployeeSatisfactionData({ commit }, payload) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_moodomat',
            list_moodomat_teams: payload?.selectTeamIdentifiers,
            list_moodomat_tags: payload?.selectTagIdentifiers,
            employeePeriodType: payload?.employeePeriodType,
            avgEmployeePeriodType: payload?.avgEmployeePeriodType,
          },
        ],
      };
      const {
        employeeSatisfactionData,
        avgEmployeeSatisfactionData,
        employeeSatisfactionText,
        teamsOptions,
        tagsOptions,
        employeeSatisfactionPeriod,
      } = await fetchData(requestData);
      commit('SET_EMPLOYEE_SATISFACTION_DATA', {
        employeeSatisfactionData,
        avgEmployeeSatisfactionData,
        employeeSatisfactionText,
        teamsOptions,
        tagsOptions,
        employeeSatisfactionPeriod,
      });
    },
  },
};
