import kss from './kss';
import mootomat from './mootomat';
import omindset from './omindset';
import weeklyReport from './weeklyReport';

export default {
  getters: {
    getWeeklyReport: (state) => state.weeklyReport,
    getKss: (state) => state.kss,
    getMoodomat: (state) => state.mootomat,
    getOmindset: (state) => state.omindset,
  },
  modules: {
    kss,
    mootomat,
    omindset,
    weeklyReport,
  },
};
