<template>
  <!----------------- BREADCRUMBS ----------------->
  <ul class="breadcrumbs">
    <li class="breadcrumbs-item" v-for="breadCrumb in breadCrumbs" :key="breadCrumb.name">
      <router-link :to="breadCrumb.goTo" :class="getLinkForClass(breadCrumb)">
        <b v-if="breadCrumb.isActive">
          {{ breadCrumb.name }}
        </b>
        <template v-else>{{ breadCrumb.name }}</template>
      </router-link>
    </li>
  </ul>
</template>

<script scoped>
export default {
  name: 'BreadcrumbsComponent',
  props: ['breadCrumbs'],
  data() {
    return {
      activeClass: 'breadcrumbs-link breadcrumbs-link-active',
      linkClass: 'breadcrumbs-link',
    };
  },
  methods: {
    getLinkForClass(breadCrumb) {
      if (breadCrumb.isActive) {
        return this.activeClass;
      }
      return this.linkClass;
    },
  },
};
</script>

<style scoped>
/*------------------ BREADCRUMBS -------------------*/
.breadcrumbs {
  max-width: 960px;
  /*margin: 0.4rem -10rem 0.5rem;*/
  padding: 0.7rem;
  font-family: sans-serif;
  margin: 0;
}

.breadcrumbs-item {
  display: inline-block;
}

.breadcrumbs-item:not(:last-of-type)::after {
  content: '|';
  margin: 0 5px;
  color: var(--color-dark);
}

.breadcrumbs-link {
  text-decoration: none;
  color: var(--color-dark);
  font-weight: 300;
}

.breadcrumbs-link:hover {
  color: var(--color-fulvous);
}

.breadcrumbs-link-active {
  color: var(--color-black);
  font-weight: 400;
}

@media (max-width: 520px) {
  .breadcrumbs {
    margin: -1rem 0;
  }
}
@media screen and (max-width: 960px) {
  .breadcrumbs{
    width: 100vw;
  }
}
@media (min-width: 521px) and (max-width: 768px) {
  .breadcrumbs {
    margin: -1rem 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .breadcrumbs {
    margin: 0;
  }
}
@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 -10rem -1rem;
  }
}

</style>
