<template>
  <breadcrumbs-component :breadCrumbs="dashboardBreadCrumbs"/>
  <div class="dashboard-container">
    <div class="wrapper">
      <div class="box">
        <div class="tabs-container">
          <input type="radio" checked="checked" name="box" id="oim_box"
                 @click="selectedTab = 'oim'">
          <label for="oim_box" class="navlabel"
                 :class="{ 'selected-tab': selectedTab === 'oim' }">OMindset</label>
          <input type="radio" name="box" id="kss_box" @click="selectedTab = 'kss'">
          <label for="kss_box" class="navlabel"
                 :class="{ 'selected-tab': selectedTab === 'kss' }">
                 {{ $t('DashboardComponent.collaborationTrend') }}
          </label>
          <input v-if="getMoodomatEnabled" type="radio" name="box" id="moodomat_box"
                 @click="selectedTab = 'moodomat'">
          <label v-if="getMoodomatEnabled" for="moodomat_box" class="navlabel"
                 :class="{ 'selected-tab': selectedTab === 'moodomat' }">Mood-O-Mat</label>
        </div>
        <div v-if="selectedTab === 'oim'" class="content">
          <div class="oim">
            <div class="headline">
              <h1 id="txt_currentOIM">{{ $t('DashboardComponent.yourCurrentOMindset') }}</h1>
              <div class="read-more">
                <router-link to="knowledge-database">
                  <i>
                    <font-awesome-icon icon="fa-circle-question"/>
                  </i>
                </router-link>
              </div>
            </div>
            <div class="diagram">
              <div class="canvas-container" v-if="oimPlotLoaded">
                <bar v-if="getOimPlot" width="500" height="500" :data="getOimPlot.data"
                     :options="getOimPlot.options" id="oi_chart"/>
                <p v-else>
                  {{ $t('DashboardComponent.notEnoughDataAvailable') }}
                </p>
                <div class="getHtml" v-for="html in getDashboardHtml"
                     :key="html._id"
                     v-html="html.content[getLocale]"/>
              </div>
              <p v-else>
                {{ getErrorMessage }}
              </p>
            </div>
            <compare-oim-component/>
          </div>
        </div>
        <div v-if="selectedTab === 'kss'" class="content">
          <div class="kss">
            <div class="headline">
              <h1 id="txt_kssHistory">{{ $t('DashboardComponent.yourKnowlExchangeHis') }}</h1>
            </div>
            <div class="diagram">
              <div class="kss_canvas-container" v-if="kssPlotLoaded">
                <Line v-if="getKssPlot" width="500" height="300" :options="getKssPlot.options"
                      :data="getKssPlot.data"/>
                <p v-else>
                  {{ $t('DashboardComponent.notEnoughDataAvailable') }}
                </p>
              </div>
              <p v-else>
                {{ getErrorMessage }}
              </p>
            </div>
          </div>
          <dashboard-kss-history-component/>
        </div>
        <div v-if="selectedTab === 'moodomat' && getMoodomatEnabled" class="content">
          <div class="moodomat">
            <div class="headline">
              <h1 id="txt_moodomat">{{ $t('DashboardComponent.yourMoodomat') }}</h1>
            </div>
            <div class="diagram">
              <div class="canvas-container" v-if="moodomatPlotLoaded">
                <Line v-if="getMoodomatPlot" width="500" height="300"
                      :options="getMoodomatPlot.options"
                      :data="getMoodomatPlot.data"/>
                <p v-else>
                  {{ $t('DashboardComponent.notEnoughDataAvailable') }}
                </p>
              </div>
              <p v-else>
                {{ getErrorMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'oim'">
        <dashboard-profile-component v-if="selectedTab === 'oim'"
                                     class="dashboard-profile-component"/>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import { Bar, Line } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
  Filler,
} from 'chart.js';
import CompareOimComponent from '@/components/Home/DashboardComponent/CompareOimComponent.vue';
import DashboardProfileComponent
  from '@/components/Home/DashboardComponent/DashboardProfileComponent.vue';
import DashboardKssHistoryComponent
  from '@/components/Home/DashboardComponent/DashboardKssHistoryComponent.vue';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
  Filler,
);

export default {
  name: 'DashboardComponent',
  props: {
    tab: {
      type: String,
      default: 'oim',
    },
  },
  components: {
    DashboardKssHistoryComponent,
    DashboardProfileComponent,
    CompareOimComponent,
    BreadcrumbsComponent,
    Bar,
    Line,
  },
  data() {
    return {
      oimPlotLoaded: false,
      kssPlotLoaded: false,
      moodomatPlotLoaded: false,
      selectedTab: this.tab,
      dashboardBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/dashboard',
          isActive: true,
          name: this.$t('BreadCrumbs.dashboard'),
        },
      ],
    };
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    getMoodomatEnabled() {
      return process.env.VUE_APP_MOODOMAT === 'true';
    },
    getOimPlot() {
      return this.$store.getters.getOimPlotData;
    },
    getKssPlot() {
      return this.$store.getters.getKssPlotData;
    },
    getMoodomatPlot() {
      return this.$store.getters.getMoodomatPlotData;
    },
    getDashboardHtml() {
      return this.$store.getters.getDashboardHtml;
    },
    getErrorMessage() {
      return this.$store.getters.getIsDashboardLoading
        ? this.$t('DashboardComponent.loading')
        : this.$t('DashboardComponent.notEnoughDataAvailable');
    },
  },
  unmounted() {
    this.$store.commit('resetPlotData');
    this.$store.commit('resetMindsetHtml');
  },
  async mounted() {
    try {
      this.$store.commit('setIsDashboardLoading', true);
      await Promise.all([
        await this.$store.dispatch('getDashboardHtml'),
        await this.$store.dispatch('getPersonalMindset'),
      ]);
      // If data avaiable show plot
      if (this.getOimPlot) {
        this.oimPlotLoaded = true;
      }
      await this.$store.dispatch('getPersonalKssPlot');
      // If data avaiable show plot
      if (this.getKssPlot) {
        this.kssPlotLoaded = true;
      }
      if (this.getMoodomatEnabled) {
        await this.$store.dispatch('getPersonalMoodomatPlot');
        // If data avaiable show plot
        if (this.getMoodomatPlot) {
          this.moodomatPlotLoaded = true;
        }
      }
      this.$store.commit('setIsDashboardLoading', false);
    } catch (error) {
      this.$store.commit('setIsDashboardLoading', false);
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: error.response.data.message,
        type: 'error',
      });
    }
  },
};
</script>

<style scoped>
/*------------------ TABSYSTEM OIM > KSS -------------------*/
.dashboard-container {
  margin: 0 auto;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
}

.box {
  position: relative;
}

.tabs-container {
  display: flex;
  flex-wrap: nowrap;
}

.box .navlabel {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;  padding: 16px;
  margin: 4px 8px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--color-milk);
  border-radius: 10px;
  flex: 1;
}

.box .content {
  padding: 0 0 20px;
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.box input[type="radio"] {
  display: none;
}

.navlabel.selected-tab {
  color: var(--color-orange);
  border: 1px solid var(--color-orange);
}

.box .content .oim {
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.box .content .kss {
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.box .content .moodomat {
  display: block;
  box-sizing: border-box;
  background: var(--color-light);
  padding: var(--card-padding);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

/*------------------ HEADLINE -------------------*/
.headline {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
}

.headline .read-more {
  font-size: 21px;
}

.headline .read-more a {
  color: var(--color-disabled);
}

.headline .read-more a:hover {
  color: var(--color-orange);
}

/*------------------ KSS COMPARE -------------------*/
.box .content .kss h2 {
  font-size: 23px;
  padding: 50px 0 10px;
}

.compare-container .space-container {
  display: flex;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding-bottom: 8px;
  flex-grow: 2;
}

.compare-container .kss-compare {
  display: flex;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding-bottom: 8px;
  flex-grow: 1;
}

.compare-container .kss-compare .team-compare {
  padding-top: 8px;
}

.toggle {
  margin: 3px 0 0 25px;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle .toggleswitch {
  display: none;
}

.roundbutton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--color-disabled);
  display: block;
  transition: all 0.3s;
  border-radius: 40px;
  cursor: pointer;
}

.roundbutton::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: block;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: all 0.3s;
}

.toggleswitch:checked + .roundbutton {
  background-color: var(--color-orange);
}

.toggleswitch:checked + .roundbutton::before {
  transform: translate(18px, 0);
}

/*------------------ DIAGRAMS -------------------*/
.diagram {
  display: block;
  position: relative;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 30px 30px 20px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
}

.canvas-container {
  display: block;
  width: 100%;
  height: 50%;
}

.kss_canvas-container {
  padding: 1rem;
  margin: 0.5rem auto;
  display: block;
  width: auto;
  height: 50%;
}

.canvas-container canvas {
  width: 100%;
  height: 100%;
}

iframe {
  background: transparent !important;
  width: 95vw;
  max-width: 800px;
  margin-left: -2em;
  margin-right: auto;
}

.ItemPreview {
  flex: 1 1 auto;
  overflow: hidden;
  outline: 0;
  background-color: transperent !important;
  position: relative;
}

.OneUp-html iframe {
  width: 100%;
  height: 100%;
  background-color: transperent !important;
  border: 0;
  box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, .15) !important;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 521px) {
  .box .content .kss #txt_kssHistory, #txt_moodomat {
    font-size: 23px;
  }
  .box .navlabel {
    margin: 16px 6px;
    padding: 16px 16px;
  }

  .diagram {
    padding: 10px 10px 20px 10px;
    width: 93vw;
  }

  .canvas-container {
    width: 100%;
    height: 100%;
    min-height: 400px;
    padding: 0;
  }

  .kss_canvas-container {
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 0;
  }
  .oim .headline h1{
    font-size: 23px;
  }

  canvas {
    min-height: 350px;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }

  .diagram {
    padding: 10px 30px 20px;
    width: 95vw;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }

  .diagram {
    width: 80vw;
  }

  iframe {
    width: 80vw;
  }

}

@media (min-width: 1249px) {
  .diagram {
    width: 90%;
  }

  iframe {
    width: 90%;
  }

  canvas {
    max-height: 600px;
  }

  .kss_canvas-container {
    width: 720px;
    height: 520px;
  }
}

</style>
