import NetworkContent from '@/helpers/networking/NetworkContent';

async function fetchData(requestData) {
  const { data: response } = await NetworkContent.getInteractiveContent(requestData);
  const collaborationTrendOverTimeData = response?.content?.contentSections[0]?.plot_kssTrend;
  const avgCollaborationTrendData = response?.content?.contentSections[0]?.plot_avgKssTrend;
  const collaborationTrendText = response?.content?.contentSections[0]?.text_kssTip;
  const teamsOptions = response?.content?.contentSections[0]?.list_kssTeams?.elements?.map(
    (element) => ({
      name: element?.caption?.de,
      identifier: element?.identifier,
      selected: element?.selected,
    }),
  );
  const tagsOptions = response?.content?.contentSections[0]?.list_kssTags?.elements;
  const collaborationTrendPeriod = response?.content?.contentSections[0]?.list_kssPeriods?.elements?.map(
    (element) => ({
      name: element?.caption?.de,
      identifier: element?.identifier,
    }),
  );
  return {
    collaborationTrendOverTimeData,
    avgCollaborationTrendData,
    collaborationTrendText,
    teamsOptions,
    tagsOptions,
    collaborationTrendPeriod,
  };
}

export default {
  state: {
    collaborationTrendOverTimeData: [],
    avgCollaborationTrendData: [],
    collaborationTrendText: {},
    teamsOptions: [],
    tagsOptions: [],
    collaborationTrendPeriod: [],
  },
  getters: {},
  mutations: {
    SET_COLLABORATION_TREND_DATA(state, data) {
      state.collaborationTrendOverTimeData = data.collaborationTrendOverTimeData;
      state.avgCollaborationTrendData = data.avgCollaborationTrendData;
      state.collaborationTrendText = data.collaborationTrendText;
      state.teamsOptions = data.teamsOptions;
      state.tagsOptions = data.tagsOptions;
      state.collaborationTrendPeriod = data.collaborationTrendPeriod;
    },
  },
  actions: {
    async fetchInitialCollaborationTrendData({ commit }, payload) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_kss',
            list_kss_teams: payload?.selectTeamIdentifiers,
            list_kss_tags: payload?.selectTagIdentifiers,
            kssPeriodType: payload?.kssPeriodType,
            avgKssPeriodType: payload?.avgKssPeriodType,
          },
        ],
      };
      const {
        collaborationTrendOverTimeData,
        avgCollaborationTrendData,
        collaborationTrendText,
        teamsOptions,
        tagsOptions,
        collaborationTrendPeriod,
      } = await fetchData(requestData);
      commit('SET_COLLABORATION_TREND_DATA', {
        collaborationTrendOverTimeData,
        avgCollaborationTrendData,
        collaborationTrendText,
        teamsOptions,
        tagsOptions,
        collaborationTrendPeriod,
      });
    },
  },
};
