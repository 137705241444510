import NetworkContent from '@/helpers/networking/NetworkContent';
import NetworkAssessment from '@/helpers/networking/NetworkAssessment';
import ContentStore from '@/store/modules/content';

export default {
  state: {
    selectedDate: null,
    selectedHours: null,
    selectedMinutes: null,
    archivedTasks: [],
    createdTasks: [],
    kssParticipants: [],
    kssTeams: [],
    involvedKssUsers: [],
    newMeetingSubject: '',
    newDescription: '',
    errorFlag: false,
    isRecurringTask: false,
    recurringType: 'daily',
    recurringSubtype: 'days',
    recurringEveryX: 1,
    recurringDays: [],
    showRuntime: true,
    showCalendar: true,
    inviteEmails: [],
    similarTasks: null,
    hasSimilarTasks: false,
  },
  getters: {
    getSelectedDate: (state) => state.selectedDate,
    getKssParticipants: (state) => state.kssParticipants,
    getKssTeams: () => ContentStore.state.teams,
    getInvolvedKssUsers: (state) => state.involvedKssUsers,
    getSelectedKssParticipantCount:
      (state) => state.kssParticipants.filter((e) => e.isChecked).length,
    getRecurringDays: (state) => state.recurringDays,
    getIsRecurringTask: (state) => state.isRecurringTask,
    getInviteEmails: (state) => state.inviteEmails,
    getCurrentArchivedTasks: (state) => state.archivedTasks,
    getCurrentCreatedTasks: (state) => state.createdTasks,
    getSelectedTime: (state) => `${(`0${state.selectedHours}`).slice(-2)}:${(`0${state.selectedMinutes}`).slice(-2)}`,
  },
  mutations: {
    setShowCalendar: (state, value) => {
      state.showCalendar = value;
    },
    setShowRuntime: (state, value) => {
      state.showRuntime = value;
    },
    setSelectedDate: (state, date) => {
      state.selectedDate = new Date(date);
    },
    setSelectedTime: (state, time) => {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      state.selectedHours = Number(hours);
      state.selectedMinutes = Number(minutes);
    },
    setKssParticipants: (state, data) => {
      data.users.forEach((el) => {
        state.kssParticipants.push({
          _id: el.id,
          name: el.name,
          teams: el.teams,
          isChecked: el.name === data.loggedInName,
        });
      });
    },
    setArchivedTasks: (state, data) => {
      state.archivedTasks = data;
    },
    setCreatedTasks: (state, data) => {
      state.createdTasks = data;
    },
    setInvolvedKssUsers: (state, data) => {
      state.involvedKssUsers = data;
    },
    setNewSubject: (state, newSubject) => {
      state.newMeetingSubject = newSubject;
    },
    setNewDescription: (state, newDescription) => {
      state.newDescription = newDescription;
    },
    setIsRecurringTask: (state, value) => {
      state.isRecurringTask = value;
    },
    setRecurringType: (state, value) => {
      state.recurringType = value;
    },
    setRecurringSubtype: (state, value) => {
      state.recurringSubtype = value;
    },
    setRecurringEveryX: (state, value) => {
      state.recurringEveryX = value;
    },
    setSimilarTasks: (state, value) => {
      state.hasSimilarTasks = !!value;
      state.similarTasks = value;
    },
    initRecurringDays: (state) => {
      state.recurringDays = [
        {
          rSchName: 'MO',
          isSelected: false,
        },
        {
          rSchName: 'TU',
          isSelected: false,
        },
        {
          rSchName: 'WE',
          isSelected: false,
        },
        {
          rSchName: 'TH',
          isSelected: false,
        },
        {
          rSchName: 'FR',
          isSelected: false,
        },
        {
          rSchName: 'SA',
          isSelected: false,
        },
        {
          rSchName: 'SU',
          isSelecte: false,
        },
      ];
    },
    selectRecurringDate: (state, index) => {
      state.recurringDays[index].isSelected = !state.recurringDays[index].isSelected;
    },
    updateKssTeamSelection: (state, index) => {
      state.kssTeams = ContentStore.state.teams;
      state.kssTeams[index].isChecked = !state.kssTeams[index].isChecked;
      state.kssParticipants.forEach((kssParticipant, i) => {
        if (kssParticipant.teams.includes(state.kssTeams[index]._id)) {
          state.kssParticipants[i].isChecked = state.kssTeams[index].isChecked;
        }
      });
    },
    updateKssParticipantSelection: (state, index) => {
      state.kssParticipants[index].isChecked = !state.kssParticipants[index].isChecked;
      // uncheck selected team, if a participant is unchecked and belongs to that team
      if (!state.kssParticipants[index].isChecked) {
        state.kssTeams.forEach((team, i) => {
          if (state.kssParticipants[index].teams.includes(team._id)) {
            state.kssTeams[i].isChecked = false;
          }
        });
      }
    },
    addInviteEmail: (state, emails) => {
      if (emails !== undefined) {
        state.inviteEmails.push(...emails);
      }
    },
    removeInviteEmail: (state, index) => {
      state.inviteEmails.splice(index, 1);
    },
    popInviteEmail: (state) => {
      state.inviteEmails.pop();
    },
    removeKssParticipants: (state) => {
      state.kssParticipants = [];
    },
    removeArchivedTasks: (state) => {
      state.archivedTasks = [];
    },
    removeCreatedTasks: (state) => {
      state.createdTasks = [];
    },
    removeKssTeams: (state) => {
      state.kssTeams = [];
    },
    resetInviteEmail: (state) => {
      state.inviteEmails = [];
    },
    resetKssFormValues: (state) => {
      state.selectedDate = null;
      state.newDescription = '';
      state.newMeetingSubject = '';
    },
    resetKssRecurringTaskValues: (state) => {
      state.recurringEveryX = 1;
      state.recurringType = 'daily';
      state.recurringSubtype = 'days';
      state.isRecurringTask = false;
    },
  },
  actions: {
    async getArchivedTasks({ commit }) {
      const { data } = await NetworkContent.getArchivedTasks();
      commit('setArchivedTasks', data.tasks);
    },
    async getCreatedTasks({ commit }) {
      const { data } = await NetworkContent.getCreatedTasks();
      commit('setInvolvedKssUsers', data.involvedUsers);
      commit('setCreatedTasks', data.tasks);
    },
    async getKssParticipants({ commit }, loggedInName) {
      const { data } = await NetworkContent.getParticipants();
      commit('setKssParticipants', {
        users: data.users,
        loggedInName,
      });
    },
    async createKssTask({
      commit,
      dispatch,
    }, taskData) {
      const { data } = await NetworkContent.createKssTask(taskData);
      if (data.success) {
        commit('resetKssFormValues');
        commit('removeKssParticipants');
        commit('removeKssTeams');
        commit('resetInviteEmail');
        commit('resetKssRecurringTaskValues');
        commit('initRecurringDays');
        commit('setSimilarTasks', null);
        dispatch('getCreatedTasks');
        dispatch('getArchivedTasks');
        dispatch('getKssTeams');
        dispatch('getKssParticipants');
      } else {
        commit('setSimilarTasks', data.similarTasks);
      }
    },
    async deleteKssTask({ dispatch }, data) {
      await NetworkContent.deleteKssTask(data);
      dispatch('getCreatedTasks');
    },
    async updateKssTask(_, data) {
      await NetworkContent.createKssTask(data);
    },
    async cancelKssTask(_, data) {
      await NetworkAssessment.cancelAssessment(data);
    },
  },
};
