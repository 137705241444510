<template>
  <canvas id="oMindSetCompanyChart" width="100%" height="100%"></canvas>
  <div class="chart-no-data" v-if="!hasData">
    Not enough data to display
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default {
  name: 'OMindSetCompanyChart',
  data() {
    return {
      hasData: false,
    };
  },
  methods: {
    OMindSetCompanyCharts(OMindSetCompanyData) {
      const ctx = document.getElementById('oMindSetCompanyChart');
      if (this.mindSetChart) {
        // Destroy the existing chart
        this.mindSetChart.destroy();
      }

      const labels = OMindSetCompanyData?.data?.labels?.map((label) => label.split(' ')) ?? [];
      const datasets = OMindSetCompanyData?.data?.datasets?.filter(
        (dataset) => dataset !== null && typeof dataset !== 'undefined',
      ) ?? [];
      datasets.forEach((r) => {
        if (r.type === 'line') {
          r.borderWidth = 2;
          r.pointRadius = 2;
        }
      });
      this.hasData = !!datasets.find((s) => s.data.find((z) => z));
      if (this.hasData === false) return;
      const options = OMindSetCompanyData?.options;

      this.mindSetChart = new Chart(ctx, {
        type: OMindSetCompanyData?.type,
        data: {
          labels,
          datasets,
        },
        options: {
          ...options,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              max: 6,
              min: 0,
            },
          },
        },
      });
    },
  },
};
</script>
