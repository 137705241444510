<template>
  <breadcrumbs-component :bread-crumbs="editProfileBreadCrumbs"/>
  <div class="edit-container">
    <div class="forms">
      <vee-form :validation-schema="schema" @submit="saveChanges"
                :initial-values="initData"
                v-slot="{ values, errors }"
                id="veeForm">
        <image-upload-component name="picture_profile"/>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.firstName}"
                     name="firstName"
                     id="txt_firstName"/>
          <label for="firstName" class="form-control">
            {{ $t('EditProfileComponent.firstName') }}
          </label>
        </div>
        <error-message class="error-message" name="firstName"></error-message>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.lastName}"
                     name="lastName"
                     id="txt_lastName"
          />
          <label for="lastName" class="form-control">
            {{ $t('EditProfileComponent.lastName') }}
          </label>
        </div>
        <error-message class="error-message" name="lastName"></error-message>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.email}"
                     name="email"
                     id="txt_mail"
          />
          <label for="email" class="form-control">
            {{ $t('EditProfileComponent.email') }}
          </label>
        </div>
        <error-message class="error-message" name="email"></error-message>
        <div class="input-field">
          <vee-field type="text"
                     class="form-control"
                     :class="{inputError : !!errors.mobileNumber}"
                     name="mobileNumber"
                     id="txt_mobile"/>
          <label for="mobileNumber" class="form-control">
            {{ $t('EditProfileComponent.mobileNumber') }}
          </label>
        </div>
        <error-message class="error-message" name="mobileNumber"></error-message>
        <p class="notification-setting">{{ $t('EditProfileComponent.NotificationSettings') }}</p>
        <p class="notification-setting-text">
          {{ $t('EditProfileComponent.NotificationSettingsText') }}
        </p>
        <div class="check-sms" :class="{checked: values.SMSallowed}">
          <checkbox-component name="SMSallowed"
                              :modelValue="values.SMSallowed"
                              :value="values.SMSallowed"/>
          <label for="SMSallowed" v-tooltip="$t('EditProfileComponent.receiveSmsTooltip')">
            {{ $t('EditProfileComponent.receiveSmsText') }}
          </label>
        </div>
        <div class="check-sms" :class="{checked: values.pushAllowed }">
          <checkbox-component name="pushAllowed"
                              :modelValue="values.pushAllowed"
                              :value="values.pushAllowed"/>
          <label for="pushAllowed" v-tooltip="$t('EditProfileComponent.receivePushTooltip')">
            {{ $t('EditProfileComponent.receivePushText') }}
          </label>
        </div>
        <div class="check-sms" :class="{checked: values.mailAllowed }">
          <checkbox-component name="mailAllowed"
                              :modelValue="values.mailAllowed"
                              :value="values.mailAllowed"/>
          <label for="mailAllowed" v-tooltip="$t('EditProfileComponent.receiveMailTooltip')">
            {{ $t('EditProfileComponent.receiveMailText') }}
          </label>
        </div>
        <br>
        <p class="notification-setting-text">
          {{ $t('EditProfileComponent.PauseNotificationsText') }}
        </p>
        <div class="check-sms" :class="{checked: values.workingDayAllowed }">
          <checkbox-component name="workingDayAllowed"
                              :modelValue="values.workingDayAllowed"
                              :value="values.workingDayAllowed"/>
          <label for="workingDayAllowed">
            <span v-html="$t('EditProfileComponent.PauseNotificationsWorkingDayText')" />
            <vee-field type="time" class="form-control input-time"
            name="workingDayStart"></vee-field>
            {{ $t('EditProfileComponent.and') }}
            <vee-field type="time" class="form-control input-time"
            name="workingDayEnd"></vee-field>
          </label>
        </div>
        <div class="check-sms" :class="{checked: values.weekendAllowed }">
          <checkbox-component name="weekendAllowed"
                              :modelValue="values.weekendAllowed"
                              :value="values.weekendAllowed"/>
          <label for="weekendAllowed"
          v-html="$t('EditProfileComponent.PauseNotificationsWeekendsText')">
          </label>
        </div>
        <div class="language">
          <span :class="getFlagIcon(values.preferredLanguage)"/>
          <label class="lang" for="preferredLanguage">
            {{ $t('EditProfileComponent.chooseLang') }}
          </label>
          <vee-field as="select" name="preferredLanguage" id="btn_language">
            <option value="en">English</option>
            <option value="de">Deutsch</option>
          </vee-field>
        </div>
      </vee-form>
    </div>
    <div class="btns">
      <button type="button" class="btn-cancel" name="cancel" id="btn_cancel">
        {{ $t('EditProfileComponent.cancel') }}
      </button>
      <button type="submit" form="veeForm" class="btn-save" name="save" id="btn_save"
              :disabled="isSubmitting">
        {{ $t('EditProfileComponent.saveChanges') }}
      </button>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import CheckboxComponent from '@/components/Home/Shared/CheckboxComponent.vue';
import ImageUploadComponent from '@/components/Home/Shared/ImageUploadComponent.vue';

export default {
  name: 'EditProfileComponent',
  components: {
    ImageUploadComponent,
    CheckboxComponent,
    BreadcrumbsComponent,
  },
  data() {
    return {
      isSubmitting: false,
      schema: {
        firstName: 'required|alphaSpaces|min:3|max:100',
        lastName: 'required|alphaSpaces|min:3|max:100',
        email: 'required|email',
        mobileNumber: 'dependent:SMSallowed|phoneNumber|max:100',
      },
      editProfileBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/edit-profile',
          isActive: true,
          name: this.$t('BreadCrumbs.editProfile'),
        },
      ],
    };
  },
  computed: {
    initData() {
      const data = this.$store.getters.getMe;
      if (data && data.pushAllowed !== false) data.pushAllowed = true;
      if (data && data.mailAllowed !== false) data.mailAllowed = true;
      if (data && data.workingDayStart) {
        data.workingDayStart = this.utcTimeToLocalTime(data.workingDayStart);
      }
      if (data && data.workingDayEnd) {
        data.workingDayEnd = this.utcTimeToLocalTime(data.workingDayEnd);
      }
      return data;
    },
  },
  methods: {
    utcTimeToLocalTime(utcTime) {
      const utcDate = new Date();
      const [hours, minutes] = utcTime.split(':');
      utcDate.setUTCHours(hours);
      utcDate.setUTCMinutes(minutes);
      const localHours = utcDate.getHours();
      const localMinutes = utcDate.getMinutes();
      return `${localHours.toString().padStart(2, '0')}:${localMinutes.toString().padStart(2, '0')}`;
    },
    async saveChanges(values) {
      const params = { ...values };
      if (values.workingDayAllowed === true) {
        if (!values.workingDayStart) {
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('PauseNotificationComponent.toast.requiredStartTime'),
            type: 'error',
          });
          return;
        }
        if (!values.workingDayEnd) {
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('PauseNotificationComponent.toast.requiredEndTime'),
            type: 'error',
          });
          return;
        }
      }
      if (values.workingDayStart && values.workingDayEnd) {
        const defaultDate = '2000-01-01T';
        const startDate = new Date(defaultDate + values.workingDayStart);
        params.workingDayStart = `${String(startDate.getUTCHours()).padStart(2, '0')}:${String(startDate.getUTCMinutes()).padStart(2, '0')}`;

        const endDate = new Date(defaultDate + values.workingDayEnd);
        params.workingDayEnd = `${String(endDate.getUTCHours()).padStart(2, '0')}:${String(endDate.getUTCMinutes()).padStart(2, '0')}`;
      }
      params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      try {
        this.isSubmitting = true;
        this.$i18n.locale = values.preferredLanguage;
        this.$store.commit('setLanguage', values.preferredLanguage);
        await this.$store.dispatch('updateProfile', params);
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          // Todo translate
          message: this.$t('EditProfileComponent.profileSaved'),
          type: 'success',
        });
      } catch (error) {
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('EditProfileComponent.errorSaved'),
          type: 'error',
        });
      }
    },
    getFlagIcon(key) {
      if (key === 'de') {
        // German flag
        return 'fi fi-de';
      }

      // Great Britain flag
      return 'fi fi-gb';
    },
  },
  mounted() {
    try {
      this.$store.dispatch('getMe');
    } catch (error) {
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: error.response.data.message,
        type: 'error',
      });
    }
  },
};
</script>

<style scoped>
.error-message {
  margin-top: 2px;
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

/*------------ CONTAINER ---------------*/
.edit-container {
  margin: 0 auto;
  position: relative;
}

.forms {
  max-width: 90vw;
  width: 600px;
  min-height: 540px;
  height: auto;
  display: block;
  margin-top: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-light);
}

input[type=text] {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding: 0.7rem var(--card-padding) 0.1rem;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 15px;
}

.input-field {
  transition: all .3s;
  height: 3.5rem;
}

.input-field label {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  opacity: 1;
  transform: translateY(-2.3rem);
  transform-origin: 0 0;
  transition: all .3s;
  padding-left: 5px;
}

.input-time::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.input-time {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}
.form-control::placeholder {
  color: transparent;
}

.form-control {
  transition: all .5s;
}

.form-control:focus {
  border-color: var(--color-orange);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  transform: translateY(-3rem) scale(.8); /*formatiert Label nach links oben und reduziert Größe*/
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
}

.language {
  margin: 50px 0 20px;
  padding: 10px;
}

.language span {
  margin-right: 5px;
}

.language .lang {
  font-size: 1rem;
  color: #aaa;
  padding-right: 12px;
}

.language select {
  font-size: 1rem;
  border: 1px solid var(--color-orange);
  border-radius: 10px;
  padding: 5px 10px;
}

.check-sms {
  display: flex;
  cursor: pointer;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: block;
  position: relative;
  margin: 3rem 0;
  box-sizing: border-box;
}

.btn-save {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 10rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-save:disabled {
  cursor: not-allowed;
}

.btn-save:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-cancel {
  float: left;
  padding: 5px;
  font-size: 20px;
  width: 8rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
}

.btn-cancel:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}
.notification-setting {
  font-weight: 600;
  padding-bottom: 0;
}
.notification-setting-text {
  font-size: 15px;
  padding-top: 0;
  padding-left: 6px;
}
.link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: underline;
}
</style>
