<template>
  <div class="mainMindSet">
    <div class="upperMindSet">
      <button
        @click="toggleVisibility"
        :style="{
          backgroundColor: showTeamsAndTags ? '#f49718' : 'white',
          color: showTeamsAndTags ? 'white' : 'black',
        }"
      >
        <img src="@/assets/eye-slash.svg" alt="eye slash" v-show="showTeamsAndTags" />
        <img src="@/assets/eye.svg" alt="eye" v-show="!showTeamsAndTags" />
        {{ showTeamsAndTags ? "Hide" : "Show" }} Teams/Tags
      </button>
      <TeamsTagsSelection
        ref="teamsTagsSelection"
        v-show="showTeamsAndTags"
        :selectedTeamsValues="selectedTeamsValues"
        :selectedTagsValues="selectedTagsValues"
        @update-teams="handleTeamsUpdate"
        @update-tags="handleTagsUpdate"
        :teamsOptions="teamsOptions"
        :tagsOptions="tagsOptions"
        :max="2"
      />
    </div>
    <div class="lowerMindSet">
      <div class="leftMindSetContainer">
        <div class="topMindSetChartContainer">
          <h1>The OMindset in your company</h1>
          <select v-model="mindSetSelectFilter" @change="applyMindSetFilter">
            <option v-for="item in mindsetPeriod" :key="item?.identifier" :value="item?.identifier">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="mindSetChartContainer">
          <OMindSetCompanyChart ref="mindSetCompany" />
        </div>
      </div>
      <div class="rightMindSetContainer">
        <div class="avgChartContainer" v-show="showAverageText">
          <div class="topAvgMindSetContainer">
            <h1>Development Potential</h1>
            <img src="@/assets/rocket.svg" alt="rocket" />
          </div>
          <div class="developmentText">
            <h3><span>1</span>Attitudes towards knowledge sharing</h3>
            <h3><span>2</span>Failure tolerance</h3>
            <h3><span>3</span>Attitudes towards knowledge sourcing</h3>
          </div>
        </div>
        <div class="mindSetTextContainer">
          <div style="display: flex">
            <img src="@/assets/light.svg" alt="light" />
            <h1>
              {{
                mindsetTipText?.headline?.en ? mindsetTipText?.headline?.en : "Sharing is caring"
              }}
            </h1>
          </div>
          <p>
            {{
              mindsetTipText?.tip
                ? mindsetTipText?.tip?.en
                  ? mindsetTipText?.tip?.en
                  : mindsetTipText?.text?.en
                : "Based on your given answers, we believe that you rather doubt that you can achieve significant results with methods of other colleagues or partners. In your opinion, working with others often generates more effort than return. Your social and professional network is expandable and you rarely actively seek exchange with people who have a different professional background than you do."
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../MngDashboard.css';
import OMindSetCompanyChart from '@/components/MngDashboard/O-mind-set/OMindSetChart.vue';
import TeamsTagsSelection from '@/components/MngDashboard/common/TeamsTagsSelection.vue';

export default {
  name: 'OMindSet',
  data() {
    return {
      selectedTeamsValues: [],
      selectedTagsValues: [],
      mindSetSelectFilter: 'Last7Days',
      showTeamsAndTags: false,
      showAverageText: true,
    };
  },
  components: {
    OMindSetCompanyChart,
    TeamsTagsSelection,
  },
  computed: {
    oMindSetCompanyData() {
      return this.$store.getters.getOmindset?.oMindSetCompanyData;
    },
    mindsetTipText() {
      return this.$store.getters.getOmindset?.mindsetTipText;
    },
    teamsOptions() {
      return this.$store.getters.getOmindset?.teamsOptions;
    },
    tagsOptions() {
      return this.$store.getters.getOmindset?.tagsOptions;
    },
    mindsetPeriod() {
      return this.$store.getters.getOmindset?.mindsetPeriod;
    },
  },
  methods: {
    formatSelectedData(selectedTeams, selectedTags) {
      const selectTeamIdentifiers = selectedTeams.map((option) => option.identifier);
      const selectTagIdentifiers = selectedTags.map((option) => option.identifier);

      return [selectTeamIdentifiers, selectTagIdentifiers];
    },

    // --------- Main Function to call the api on every filters change ---------------- //
    async mindSetOverAllFunction() {
      const [selectTeamIdentifiers, selectTagIdentifiers] = this.formatSelectedData(
        this.selectedTeamsValues,
        this.selectedTagsValues,
      );

      try {
        await this.$store.dispatch('fetchInitialMindSetCompanyData', {
          selectTeamIdentifiers,
          selectTagIdentifiers,
          mindsetPeriodType: this.mindSetSelectFilter,
        });
        this.$refs.mindSetCompany.OMindSetCompanyCharts(this.oMindSetCompanyData);
      } catch (error) {
        console.error('Error dispatching action:', error);
      }
    },

    // -----------show / hide Teams and Tags function -------------- //
    toggleVisibility() {
      this.showTeamsAndTags = !this.showTeamsAndTags;
      if (this.selectedTeamsValues.length > 0 || this.selectedTagsValues.length > 0) {
        this.selectedTeamsValues = [];
        this.selectedTagsValues = [];
        this.$refs.teamsTagsSelection.clearSelection();
        this.mindSetOverAllFunction('');
      }
    },

    // toggleAverageText() {
    //   this.showAverageText = !this.showAverageText;
    // },

    // --------------- Filter---------------- //
    applyMindSetFilter() {
      this.mindSetOverAllFunction();
    },

    // -------------- Teams Handler------------- //
    handleTeamsUpdate(updatedTeams) {
      this.selectedTeamsValues = updatedTeams;
      this.mindSetOverAllFunction('');
    },

    // -------------- Tags Handler------------- //
    handleTagsUpdate(updatedTags) {
      this.selectedTagsValues = updatedTags;
      this.mindSetOverAllFunction('');
    },

    // ------------ O MindSet Handler --------------- //
    fetchInitialOMidSetCompanyData() {
      this.mindSetOverAllFunction();
    },
  },
  mounted() {
    this.fetchInitialOMidSetCompanyData();
  },
};
</script>
