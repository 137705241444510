<template>
  <div class="icon-and-text">
    <font-awesome-icon :icon="['fas', 'users-viewfinder']" class="users-icon"/>
    <p class="info-text">{{ $t('Mindset360CreatorComponent.furtherInfo') }}</p>
  </div>
  <p class="description-text" v-html="$t('Mindset360CreatorComponent.description')"></p>
  <!----------------- ADD PARTICIPANTS ----------------->
  <div class="select-container">
    <div class="participants-container" id="btn_select" @click.prevent="toggleParticipants">
        <span class="circle-plus">
          <font-awesome-icon icon="fa-circle-plus"/>
        </span>
      <span class="btn-text"
            id="btn_textParticipants">{{ $t('Mindset360CreatorComponent.addParticipants') }}</span>
      <div v-if="!showParticipants" class="pill-container">
        <div v-for="(selectedParticipant) in selectedParticipants" :key="selectedParticipant"
             class="pill-element">
          {{ selectedParticipant.name }}
        </div>
      </div>
    </div>
    <mindset360-participants-selection-component v-if="showParticipants"/>
  </div>
  <!-------------- ADD EXTERNAL PARTICIPANTS ----------->
  <div class="select-container">
    <div class="email-container" id="email_invite" @click.prevent="toggleEmailInvite">
            <span class="circle-plus">
              <font-awesome-icon icon="fa-circle-plus"/>
            </span>
      <span class="btn-text"
            id="email_inviteParticipants">
          {{ $t('Mindset360CreatorComponent.externalInvites') }}
        </span>
      <div v-if="!showEmailInvite" class="pill-container">
        <div v-for="externalEmail in externalEmails" :key="externalEmail"
             class="pill-element">
          {{ externalEmail }}
        </div>
      </div>
    </div>
    <external-users-invite-component v-if="showEmailInvite"/>
  </div>
  <div class="btns">
    <button type="button" class="btn-create" name="create" id="btn_cancel360"
            @click.prevent="closePopup">
      {{ $t('Mindset360CreatorComponent.cancel') }}
    </button>
    <button type="button" class="btn-create" name="create" id="btn_create360"
            @click.prevent="createMindset360">
      {{ $t('Mindset360CreatorComponent.sendRequest') }}
    </button>
  </div>

</template>

<script>
import ExternalUsersInviteComponent from '@/components/Home/KssCreator/ExternalUsersInviteComponent.vue';
import Mindset360ParticipantsSelectionComponent
  from '@/components/Home/Mindset360Creator/Mindset360ParticipantsSelectionComponent.vue';

export default {
  name: 'Mindset360CreatorComponent',
  props: {
    data: Object,
  },
  components: {
    Mindset360ParticipantsSelectionComponent,
    ExternalUsersInviteComponent,
  },
  data() {
    return {
      showParticipants: false,
      showEmailInvite: false,
    };
  },
  computed: {
    externalEmails() {
      return this.$store.getters.getInviteEmails;
    },
    selectedParticipants() {
      return this.$store.getters.get360Participants.filter((el) => el.isChecked);
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    async createMindset360() {
      if (this.checkForErrors()) {
        return;
      }
      const data = {
        involvedUsers: this.$store.state.mindset360.mindset360Participants
          .filter((e) => e.isChecked)
          .map((e) => e._id),
        involvedExternalUsers: this.$store.state.kss.inviteEmails,
      };
      try {
        await this.$store.dispatch('create360Task', data);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.mindset360.mindset360Created'),
          type: 'success',
        });
        this.closePopup();
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    toggleParticipants() {
      this.showParticipants = !this.showParticipants;
    },
    toggleEmailInvite() {
      this.showEmailInvite = !this.showEmailInvite;
    },
    checkForErrors() {
      let hasError = false;
      const selectedUserCount = this.selectedParticipants.length + this.externalEmails.length;

      if (selectedUserCount < 7 || selectedUserCount > 10) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.mindset360.mindset360UserCount'),
          type: 'error',
        });
        hasError = true;
      }
      return hasError;
    },
  },
  mounted() {
    this.$store.dispatch('get360Participants', this.$store.state.auth.user.name);
  },
  unmounted() {
    this.$store.commit('reset360Participatns');
    this.$store.commit('resetInviteEmail');
  },
};
</script>

<style scoped>
.description-text {
  font-weight: 500;
  font-size: large;
  margin-left: 10px;
}

.users-icon {
  font-size: 32px;
  margin-right: 10px
}

.icon-and-text {
  margin: 20px;
  display: flex;
  align-items: center;
}

.info-text {
  font-family: "Poppins";
  font-size: 20px;
}

/*------------------ ADD PARTICIPANTS -------------------*/
.select-container {
  position: relative;
  width: 100%;
  margin-top: 8px;
}

.participants-container {
  display: flex;
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
}

.participants-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.participants-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin-left: 10px;
}

.participants-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.participants-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.participants-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.email-container {
  display: flex;
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  align-items: center;
  min-height: 40px;
}

.email-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.email-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin-left: 10px;
}

.email-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.email-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.email-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: none;
  overflow: hidden;
  z-index: 10;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  margin: 2rem 0;
  box-sizing: border-box;
}

.btn-create {
  margin-right: 10px;
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

@media screen and (max-width: 767px) {
  .btns {
    flex-direction: column;
    align-items: flex-end;
  }

  .btn-create {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

</style>
