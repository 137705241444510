<template>
  <canvas id="avgKSSTrendChart" width="100%" style="max-height: 180px"></canvas>
  <div class="chart-no-data" v-if="!hasData">
    Not enough data to display
  </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
  name: 'AvgCollaborationTrendChart',
  data() {
    return {
      hasData: false,
    };
  },
  methods: {
    avgCollaborationTrendChart(avgKSSTrendData) {
      // Get the chart container element
      const ctx = document.getElementById('avgKSSTrendChart');
      // Check if a chart already exists on this canvas
      if (this.avgKSSTrendChart) {
        // Destroy the existing chart
        this.avgKSSTrendChart.destroy();
      }
      ctx.parentNode.style.width = '100%';
      ctx.parentNode.style.height = '100%';
      const labels = avgKSSTrendData?.data?.labels ?? [];
      const datasets = avgKSSTrendData?.data?.datasets?.filter(
        (dataset) => dataset !== null && typeof dataset !== 'undefined',
      ) ?? [];
      this.hasData = !!datasets.find((s) => s.data.find((z) => z));
      if (this.hasData === false) return;
      const options = avgKSSTrendData?.options ?? [];
      const percentageInCenter = {
        id: 'percentageInCenter',
        beforeDatasetsDraw(chart) {
          const { ctx, data } = chart;
          ctx.save();
          const xCoor = chart.getDatasetMeta(0)?.data[0]?.x;
          const yCoor = chart.getDatasetMeta(0)?.data[0]?.y;
          const circumference = chart.getDatasetMeta(0).data[0]?.innerRadius;
          // Define the submittedValue and createdValue values
          const submittedValue = data.datasets[0]?.data[0];
          const createdValue = submittedValue + Number(data?.datasets[0]?.data[1]);

          // Calculate the percentage
          const percentage = (submittedValue / createdValue) * 100;

          function textLabel(text, x, y, fontSize, textBaseLine, textAlign) {
            ctx.font = `${fontSize}px sans-serif`;
            ctx.fillStyle = 'black';
            ctx.textBaseLine = textBaseLine;
            ctx.textAlign = textAlign;
            ctx.fillText(text, x, y);
          }
          // textLabel(
          //   data.datasets[0]?.data[0],
          //   xCoor - circumference - 30,
          //   yCoor + 20,
          //   20,
          //   "top",
          //   "center" + 100
          // );
          textLabel(
            createdValue,
            xCoor + circumference + 20,
            yCoor + 20,
            13,
            'top',
            'center' - 100,
          );
          textLabel(`${Math.floor(percentage)}%`, xCoor, yCoor - 10, 30, 'bottom', 'center');
        },
      };
      this.avgKSSTrendChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels, // Populate with your date labels
          datasets, // Assign your datasets
        },
        options: {
          ...options,
          layout: {
            padding: {
              right: 0,
              left: 0,
              bottom: 40,
            },
          },
          plugins: {
            ...options?.plugins,
            legend: {
              ...options?.plugins?.legend,
              labels: {
                usePointStyle: true,
                boxWidth: 6,
                boxHeight: 6,
              },
            },
          },

          cutout: '60%',
        },
        plugins: [percentageInCenter],
      });
    },
  },
};
</script>
